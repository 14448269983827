<template>
  <div class="horizontal-list">
    <div class="items  wrapper-content">
      <div class="item-group-word" v-for="(gItem,index) in groupItems" :key="index"
           @click="this.emitter.emit('savedSubGroupItem',gItem)">
        <div class="word">
          <span>{{ gItem.subGroup.en }}</span>
          <div class="mean">
            <span>{{ gItem.subGroup.fa }}</span>
          </div>
        </div>

        <div class="count">
          <span>{{gItem.items.length }}</span>
          <font-awesome-icon class="i-icon" icon="fa-solid fa-comment-dots"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default
{
  props:{
    groupItems:{
      type:Array,
      required:true,
    },
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.horizontal-list
{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
 .items{
   width: 100%;
   align-items: start;
   margin: 0;
   overflow-x: auto;
   overflow-y: hidden;
   display: flex;
   flex-direction: row-reverse;
   &::-webkit-scrollbar
   {
     display: none;
   }
   .item-group-word {
     min-width: 210px;
     margin-inline-end:15px ;
     display: flex;
     flex-direction: column;
     background-color: $background-color-2;
     border-radius: 5px;
     padding: 10px;
     border:1px solid $color-menu;

     .word {
       display: flex;
       flex-direction: column;
       align-items: flex-end;
       color: $text-color;
       font-weight: 700;
       font-size: $small-font-size;

       .mean {
         color: $key-color;
         margin-top: 10px;
         font-size: $small-font-size;
       }
     }

     .count {
       display: flex;

       flex-direction: row;
       color: #DFDAFD;

       span {
         font-size: $small-font-size;
       }

       .i-icon {
         color: $key-color;
         margin-inline: 5px;
         font-size: $small-icon-size;
       }
     }
   }
 }
}

</style>