<template>
  <div class="advertisement-content wrapper-content">
	<div class="timer">
		<span class="showTimer">{{ getMin() }}:{{ getSec() }}</span>
	</div>
  </div>
	<show-alert-modal
		:item="{
		text:modalText,
		btnTitle:'فهمیدم'
	}"
		:on-ok-click="confirmModal"/>
</template>

<script>


import API_USER from "@/api/API_USER";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import {Modal} from "bootstrap";

export default {
  components: {ShowAlertModal},
  mounted() {
	this.textModal = new Modal(document.getElementById('show-alert-modal'));

  },
  created() {
    this.countDownTimer();
  },
  methods: {
	confirmModal(){
		this.textModal.hide();
		this.$root.pageRoute('Home');
	},
    getMin() {
      let min = parseInt(this.countDown / 60, 10) % 60
      if (min < 10) {
        return '0' + min;
      } else {
        return min;
      }
    },

    getSec() {
      let sec = this.countDown % 60
      if (sec < 10) {
        return '0' + sec;
      } else {
        return sec;
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {

		new API_USER().setVideoAdvertisementScore().then(result => {

		if(result.general.status)
		{
			this.modalText=result.general.text;
			this.textModal.show();

		}
		});
      }
    },
  },

  data() {
    return {
		countDown: 5,
		group: null,
		modalText:'',
		textModal:'',
    }
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.advertisement-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

	.timer {
		align-items: center;
		display: flex;
		color: #FFEE43;
		margin-bottom: 10px;
	}


}
</style>