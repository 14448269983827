<template>
  <div class="menus">
    <div class="items">
      <div v-for="(menuItem,index) in menuItems" :key="index" @click="changeActiveMenu(menuItem)">
        <font-awesome-icon class="b-icon" :class=" {'active':menuItem.active}" :icon="menuItem.icon"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
	data() {
		return {
			menuItems: [
				{icon: 'fa-solid fa-bookmark', text: 'bookmark', active: false, goto: 'Saved'},
				{icon: 'fa-solid fa-solid fa-gem', text: 'gem', active: false, goto: 'Gem'},
				{icon: 'fa-solid fa-border-all', text: 'Chats', active: false, goto: 'Home'},
			],
		}
	},
	mounted() {
		this.setActiveMenu()
	},

	methods: {
		setActiveMenu() {
			this.menuItems.forEach((item) => {
				if (item.goto === this.$route.name) {
					item.active = true;
				}
			});
		},
		changeActiveMenu(activeItem) {

			this.menuItems.forEach((item) => {
				item.active = false;
			});

			activeItem.active = true;

			this.$root.pageRoute(activeItem.goto);
		}
	},
}
</script>

<style lang="scss" scoped>
@import "../assets/css/scss/style";

.menus {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;

  .items
  {

    display: flex;
    background-color: $background-menu;
    border-radius: 50px;
    flex-direction: row;
    height: 50px;
    justify-content: space-around;
    padding: 10px;
    align-items: center;
    margin-bottom: 5px;
    flex: 1;
    margin-inline: 5%;

    .b-icon {
      font-size: $large-icon-size+3;
      color: $color-menu;

      &.active {
        color: $text-color;
      }
    }
  }
}

</style>