<template>
  <div class="profile-content">

	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left "></div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="wrapper-content">

      <wallet-skeleton v-if="!dataIsLoad"/>

      <div v-if="dataIsLoad" class="wallet mb-4">
        <div class="img">
          <font-awesome-icon class="i-icon" icon="fa-solid fa-gem"/>
        </div>
        <div class="text">
          <span class="see" v-if="userInfo!=null"> موجودی {{new Intl.NumberFormat('en-US').format(userInfo.balance)}} تومان</span>
          <span v-if="userInfo!=null">حداقل مبلغ برداشت : {{new Intl.NumberFormat('en-US').format(userInfo.minWithdraw)}} تومان</span>
        </div>
        <div class="buttons">
          <div class="btn-success" v-if=" userInfo!=null && userInfo.balance >= userInfo.minWithdraw">
            <span @click="$root.pageRoute('NewWithdrawalPage')">برداشت  جایزه</span>
          </div>

          <div class="btn-null" v-if="userInfo!=null && userInfo.balance < userInfo.minWithdraw">
            <span>برداشت  پول</span>
          </div>
        </div>
      </div>

      <div class="vertical-list">
        <div class="items" >
          <div class="item">
            <div class="number" >
              <font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
              <span v-if="userInfo!=null">{{userInfo.email}}</span>
            </div>
            <span class="text">ایمیل</span>
          </div>

			<div class="item" @click="changeTranslate">
				<div class="number" >
					<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
					<span v-if="showTranslate!=null" :class="{'text-danger':!showTranslate}">{{ (showTranslate) ? 'فعال' : 'غیرفعال'}}</span>

				</div>
				<span class="text">نمایش ترجمه</span>
			</div>

			<div class="item" @click="()=>this.$root.pageRoute('WithdrawalList')">
				<div class="number" >
					<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
				</div>
				<span class="text">برداشت های من</span>
			</div>

			<div class="item"  @click="()=>this.$root.pageRoute('ReferralRegister')">
				<div class="number" >
					<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
				</div>
				<span class="text">ثبت کد معرف</span>
			</div>

			<div class="item" @click="()=>this.$root.pageRoute('Invitation')">
				<div class="number" >
					<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
					<span v-if="userInfo!=null"> دوستان {{userInfo.friendsCount}} نفر</span>

				</div>
				<span class="text">دعوت دوستان</span>
			</div>

			<div class="item" @click="()=>outAccountModal.show()">
				<div class="number" >
					<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
				</div>
				<span class="text">خروج  از اپلیکیشن</span>
			</div>
        </div>
      </div>
    </div>
  </div>

  <out-account-modal
    :on-ok-click="logout"
    :on-cancel-click="()=>outAccountModal.hide()"
  />

	<LoadingFullScreen :show="showFullLoading"/>

</template>

<script>

import {Modal} from 'bootstrap';
import OutAccountModal from "@/components/OutAccountModal.vue";
import WalletSkeleton from "@/components/skeleton/WalletSkeleton.vue";
import API_USER from "@/api/API_USER";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
export default {
  components: {LoadingFullScreen, WalletSkeleton, OutAccountModal},
	beforeMount() {
		this.showTranslate=JSON.parse(localStorage.getItem("showTranslate"));
	},
	mounted() {
	this.getUserInfo()
    this.outAccountModal = new Modal(document.getElementById('out-account-modal'));
  },
  methods: {
	getUserInfo(){
		this.dataIsLoad=false;
		new API_USER().getUserInfo().then(result => {
			this.dataIsLoad=true;
			if(result.general.status)
			{
				this.userInfo=result.data.user
				localStorage.setItem("userData",JSON.stringify(result.data.user))
			}
		});
	},

    logout(){
		this.showFullLoading=true;
		new API_USER().logout().then(result => {
			this.showFullLoading=false;

			this.outAccountModal.hide()
			if (result.general.status) {
				localStorage.clear()
				this.$root.pageRoute('Splash');
			}
		});
    },

    changeTranslate() {
		this.showTranslate=!this.showTranslate
		localStorage.setItem("showTranslate",JSON.stringify(this.showTranslate))
    },
  },
  data() {
    return {
		showTranslate:null,
		showFullLoading:false,
		dataIsLoad:false,
		userInfo:null,
		showTitleModalVariable: null,
		ShowInvitationModalVariable: null,
		outAccountModal:null,
    }
  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.profile-content {
  .wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    flex-direction: column;
    background-color: $reward-color;

    .i-icon {
      color: $text-color;
      font-size: $super-extra-icon-size - 10;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .see {
        color: $text-color;;
        margin: 10px 0;
        font-size: $normal-font-size;
      }

      span {
        color: $yel-color;
        font-size: $small-font-size;
      }
    }

    .buttons {
      width: 40%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .btn-success {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          background-color: $text-color;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          color: $background-color;
          font-size: $small-font-size;
          width: 100%;
          font-weight: 700;
          border-radius: 5px;
        }
      }

      .btn-null {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          background-color: $background-color;
          height: 40px;
          user-select: none;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $input-color;
          font-size: $small-font-size;
          width: 100%;
          font-weight: 700;
          border-radius: 5px;

        }
      }
    }
  }

  .wrapper-content
  {

    .vertical-list {
      display: flex;
      flex-direction: row;

      .items {
        width: 100%;
        align-items: start;
        margin: 0;
        flex-wrap: wrap;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: row-reverse;

        &::-webkit-scrollbar {
          display: none;
        }

        .item {
          width: 100%;
          margin: 5px 0;
          display: flex;
          flex-direction: row;
          height: 55px;
          background-color: $color-number;
          justify-content: space-between;
          border-radius: 5px;
          align-items: center;
          padding: 10px;
          margin-inline: 10px;

          .number {
            color: $text-color;
            font-size: $very-small-font-size;
            display: flex;

            .b-icon {
              margin-inline: 10px;
              font-size: $small-font-size;
            }
          }

          .text {
            color: $name-box-color;
            font-size: $small-font-size;
            margin-inline-end: 10px;
          }
        }
      }
    }
  }


}

</style>