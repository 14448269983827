<template>
  <div v-if="show" class="loading-state">
    <div class="loading"></div>
  </div>

</template>

<script>
export default
{
  props:{
    show:{
      type:Boolean,
      required:true,
    }
  },
}
</script>

<style lang="scss" scoped>

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid rgba(#0083F4,0.1);
    border-top-color: #0083F4;
    animation: loading 1s linear infinite;
  }
}
</style>