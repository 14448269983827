<template>
  <div id="show-alert-modal" class="modal fade show-alert-modal" tabindex="-1" aria-labelledby="staticBackdropLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content s-t-content">
        <div class="modal-body s-t-body">
          <h2>{{item.text}}</h2>
          <div class="buttons">
            <div class="btn-null" @click="onOkClick">
              <span>{{item.btnTitle}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    onOkClick: {
      type: Function,
    }

  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/scss/style";

.show-alert-modal {
  .s-t-content {
    background-color: $reward-color;
    border-radius: 10px;
    .s-t-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;
      h2 {
        color: $name-box-color;
        margin: 20px 0;
        font-size: $normal-font-size;
        text-align: center;
        line-height: 25px;
      }


      .buttons {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-block: 5px;

        .btn-null {
          width: 100%;
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            background-color: $background-color;
            height: 40px;
            border: 1px solid $name-box-color;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $name-box-color;
            font-size: $small-font-size;
            width: 100%;
            font-weight: 700;
            border-radius: 5px;

          }
        }
      }


    }
  }
}

</style>