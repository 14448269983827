<template>
	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left "></div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>


  <div class="invitationPage wrapper-content">
    <div class="box-title ">
      <div class="roll"></div>
      <span>معرفی به دوستان</span>
    </div>
    <div class="content-invitation">
      <div class="items">

        <div class="i-input ">
          <span>کد دعوت شما</span>
          <input type="text" v-model="userInfo.referralCode" readonly>
        </div>
        <div class="invitation">
          <p>دوستانتان می توانند با ثبت کد دعوت شما در بخش (ثبت کد دعوت) به شما {{ userInfo.referralScore }} امتیاز هدیه دهند و دوست شما نیز
{{ userInfo.registerReferralScore }} امتیاز دریافت خواهد کرد</p>
        </div>

      </div>
    </div>
  </div>



</template>

<script>


export default {
	beforeMount() {
		this.userInfo=JSON.parse(localStorage.getItem("userData"))
	},
	mounted() {
  },
  methods:{

  },
  data() {
    return {
		userInfo:null,
    }
  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.box-title {
  display: flex;
  margin: 12px 0;
  align-items: center;
  flex-direction: row;


  span {
    color: $name-box-color;
    font-weight: bold;
    font-size: $normal-font-size+2;
    min-width: fit-content;
    margin-inline-end: 5%;
  }

  .roll {
    background-color: $roll-box-color;
    width: 100%;
    height: 1px;
    margin-inline: 10px;
  }
}

.invitationPage {
  display: flex;
  flex-direction: column;
	justify-content: center;
  margin: 0;


  .content-invitation {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .items {
      align-items: center;
      justify-items: center;
      margin-inline: 10px;
      margin: 10px 0;
      width: 90%;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      background-color: $reward-color;
      flex-direction: column;

      &::-webkit-scrollbar {
        display: none;
      }

      .i-input {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        flex-direction: column;

        span {
          color: $name-box-color;
          margin-bottom: 5px;
          font-size: $small-font-size;
        }

        input {
          display: flex;
          height: 60px;
          width: 100%;
			text-align: center;
          outline: none;
          color: $text-color;
          padding-inline: 10px;
          font-size: $normal-font-size + 3;
          background-color: $background-color-2;
          border-radius: 4px;
          border: 1px solid $text-color-2;
        }
      }

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 0;
        justify-content: center;

        .btn-success {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            background-color: $text-color;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $background-color;
            font-size: $small-font-size;
            width: 100%;
            font-weight: 700;
            border-radius: 5px;
          }
        }

      }

      .invitation {
        display: flex;

        p {
          display: flex;
          color: $yel-color;
          margin: 10px 0;
         text-align: center;
          font-size: $small-font-size;
        }
      }
    }
  }
}

</style>