import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import LoginPage from "@/pages/LoginPage.vue";
import LoginValidationPage from "@/pages/LoginValidationPage.vue";
import MainPage from "@/pages/MainPage.vue";
import HomePage from "@/pages/HomePage.vue";
import GemPage from "@/pages/GemPage.vue";
import SavedPage from "@/pages/SavedPage.vue";
import SplashPage from "@/pages/SplashPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import KeyboardGamePage from "@/pages/KeyboardGamePage.vue";
import NewWithdrawalPage from "@/pages/NewWithdrawalPage.vue";
import WithdrawalListPage from "@/pages/WithdrawalListPage.vue";
import InvitationPage from "@/pages/InvitationPage.vue";
import WordReviewPage from "@/pages/WordReviewPage.vue";
import EnWordGamePage from "@/pages/EnWordGamePage.vue";
import FaWordGamePage from "@/pages/FaWordGamePage.vue";
import SavedWordReviewPage from "@/pages/SavedWordReviewPage.vue";
import AdvertisementPage from "@/pages/AdvertisementPage.vue";
import VideoAdvertisementPage from "@/pages/VideoAdvertisementPage.vue";
import ReferralRegisterPage from "@/pages/ReferralRegisterPage.vue";


const routes = [
    {
        path: '/',
        redirect: {path: "/Splash"}
    },
    {
        path: '/Splash',
        name: 'Splash',
        component: SplashPage
    },
     {
        path: '/Login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/LoginValidation',
        name: 'LoginValidation',
        component: LoginValidationPage
    },
      {
        path: '/MainPage',
        name: 'MainPage',
        component: MainPage,
        children: [
            {
                path: '/Home',
                name: 'Home',
                component: HomePage
            },
            {
                path: '/Gem',
                name: 'Gem',
                component: GemPage
            },
            {
                path: '/Saved',
                name: 'Saved',
                component: SavedPage,

            },
            {
                path: '/Profile',
                name: 'Profile',
                component: ProfilePage,
                children: []
            },
            {
                path: '/NewWithdrawalPage',
                name: 'NewWithdrawalPage',
                component: NewWithdrawalPage
            },
            {
                path: '/WithdrawalList',
                name: 'WithdrawalList',
                component: WithdrawalListPage
            },
            {
                path: '/Invitation',
                name: 'Invitation',
                component: InvitationPage
            },
            {
                path: '/SavedWordReview/',
                name: 'SavedWordReview',
                component: SavedWordReviewPage,
            },
            {
                path: '/WordReview/:group',
                name: 'WordReview',
                component: WordReviewPage,
            },
            {
                path: '/EnWordGame/:group',
                name: 'EnWordGame',
                component: EnWordGamePage,
            },
            {
                path: '/FaWordGame/:group',
                name: 'FaWordGame',
                component: FaWordGamePage,
            },
            {
                path: '/KeyboardGame/:group',
                name: 'KeyboardGame',
                component: KeyboardGamePage,
            },
            {
                path: '/Advertisement/',
                name: 'Advertisement',
                component: AdvertisementPage,
            },
            {
                path: '/VideoAdvertisement/',
                name: 'VideoAdvertisement',
                component: VideoAdvertisementPage,
            },
            {
                path: '/ReferralRegister/',
                name: 'ReferralRegister',
                component: ReferralRegisterPage,
            },
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;