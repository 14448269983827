<template>
  <div class="splash-content wrapper-content">
    <div class="pic">
      <img src="../assets/img/logo.png" alt="logo">
    </div>
  </div>
</template>

<script>

import {GLOBAL} from "@/incloude/global";

export default {

	mounted() {
		this.setTranslate()
		this.checkUserLogin()
	},
	methods:{
		setTranslate(){
			if (localStorage.getItem("showTranslate") === null)
			{
				localStorage.setItem("showTranslate",'true');

				GLOBAL.showTranslate=JSON.parse(localStorage.getItem("showTranslate"));
			}
		},
		checkUserLogin(){
			if (localStorage.getItem("token")=== null || localStorage.getItem("userData")=== null)
			{
				this.$root.pageRoute('Login');
			}
			else
			{
				this.$root.pageRoute('Home');
			}
		}
	},

	data()
	{
		return {

		}
	},
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.splash-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
</style>