<template>
  <div class="keyboard-game-content ">
	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left "></div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>

    <EnWordGamePageSkeleton v-if="showLoading"/>

    <div class="items wrapper-content" v-if="!showLoading">
      <div class="up">

        <div class="box-title">
          <div class="roll"></div>
          <span>{{subGroup.fa}}</span>
        </div>

        <div class="show-word">

          <div class="title">
            <span class="name">{{currentWord.fa}}</span>
            <div class="mean" :class="{'d-none': !currentWord.quiz.isUserAnswer}"><span>{{currentWord.en}}</span></div>
            <div class="roll"></div>
          </div>

          <div class="control-btn">
            <font-awesome-icon v-if="currentWord.quiz.isUserAnswer" class="b-icon" :class="{active: activeSpeech}" icon="fa-solid fa-play" @click="playSpeech(currentWord.en)"/>
            <font-awesome-icon class="b-icon" :class="{active: currentWord.isSave}" icon="fa-solid fa-bookmark" @click="saveWord(currentWord.id)"/>
          </div>

          <div class="roll"></div>

          <div class="keyword">
            <div class="key" v-if="currentWord.quiz.isUserAnswer">
              <span :class="{'not-correct':!chr.isCorrect}" v-for="(chr,index) in currentWord.quiz.quizResult" :key="index">{{chr.char}}</span>
            </div>

            <div class="key" v-if="!currentWord.quiz.isUserAnswer">
              <span v-for="(aChar,index) in answerArray" :key="index" @click="answerCharClick(index)">{{aChar.char}}</span>
            </div>
          </div>

        </div>

        <div class="keyboard">
          <div class="key">
            <span :class="{'active':qChar.use}" v-for="(qChar,index) in quizArray" :key="index" @click="quizCharClick(index)">{{qChar.char}}</span>
          </div>
        </div>

      </div>
      <div class="down">
        <div class="button">
          <div class="end-items">
            <div class="next-bar">
				<div class="left" @click="goBackWord">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
					</div>
				</div>
				<div class="center">
					<div class="left">
						<p>{{words.length}}</p>
						<p class="count"> / {{getCurrentWordIndex(currentWord)+1}}</p>
					</div>
				</div>
				<div class="right" @click="goNextWord">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right"/>
					</div>
				</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <show-alert-modal
    :item="{
    text:alertText,
    btnTitle:'بله . متوجه شدم'
  }"
    :on-ok-click="()=>{showAwardAlertModal.hide()}"/>

  <audio controls id="win-player" class="d-none">
    <source src="../assets/sounds/win.mp3" type="audio/mpeg">
  </audio>

  <audio controls id="lose-player" class="d-none">
    <source src="../assets/sounds/lose.mp3" type="audio/mpeg">
  </audio>

	<LoadingFullScreen :show="sendQuizLoading"/>

	<FilterBanner :show="filterBannerShow" :on-btn-click="changeFilterBanner"/>

</template>

<script>
import {Modal} from "bootstrap";
import EnWordGamePageSkeleton from "@/components/skeleton/EnWordGamePageSkeleton.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import API_WORD from "@/api/API_WORD";
import API_SAVE from "@/api/API_SAVE";
import API_USER from "@/api/API_USER";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
import FilterBanner from "@/components/FilterBanner.vue";


export default {
  components: {FilterBanner, LoadingFullScreen, ShowAlertModal, EnWordGamePageSkeleton},
  mounted() {

    this.winPlayer = document.querySelector("#win-player");
    this.losePlayer = document.querySelector("#lose-player");

    this.showAwardAlertModal = new Modal(document.getElementById('show-alert-modal'));

	this.getWords(this.$route.params.group);


	//Android Custom Event
	document.addEventListener('SetGameScoreMethod', this.SetGameScoreMethod);
	document.addEventListener('showFilterBannerMethod', this.showFilterBannerMethod);

  },

  methods: {

	showFilterBannerMethod(){
	this.filterBannerShow=true;
	},

	changeFilterBanner(show){
	this.filterBannerShow=show;
	},

	SetGameScoreMethod(){
	this.videoAdvertisementWatched=true;
	},

	getWords(groupId){
		let that = this;
		let params = {
			"subGroupId": groupId
		};

		that.showLoading = true;

		new API_WORD().getWordsWithSpellQuiz(params).then(result => {
			that.showLoading = false;

			if (result.general.status) {
				that.words = result.data.words;
				that.subGroup = result.data.subGroup;
				this.setCurrentWord(0);
			}
		});
	},

    setCurrentWord(arrayIndex) {
      this.currentWord = this.words[arrayIndex];
      this.makeAnswerArray(this.currentWord.en)
      this.makeQuizArray(this.currentWord.en)
    },

    makeQuizArray(word){

      this.quizArray=[];

      let randomizeWordArray=this.randomChangeArrayItems(this.addThreeRandomCharToWordArray(this.wordToChar(word)));

      randomizeWordArray.forEach((item) => {
        this.quizArray.push({
          char:item,
          use:false,
        })
      });
    },

    makeAnswerArray(word){

      let wordArray=this.wordToChar(word)

      this.answerArray=[];

      wordArray.forEach(() => {
        this.answerArray.push({
          char:'',
          full:false,
          qIndex:'',
          isCorrect:false
        })
      });
    },

    quizCharClick(clickedCharIndex){

      if (!this.currentWord.quiz.isUserAnswer)
      {
        this.quizArray.forEach((qItem,index) => {
          if (clickedCharIndex===index)
          {
            if (!this.answerArrayIsCompletePlace())
            {
              if (!qItem.use)
              {
                qItem.use=true;

                for (let i = 0; i < this.answerArray.length; i++)
                {
                  if (!this.answerArray[i].full)
                  {
                    this.answerArray[i].char = qItem.char;
                    this.answerArray[i].full = true;
                    this.answerArray[i].qIndex = clickedCharIndex;
                    break;
                  }
                }
              }

              if (this.answerArrayIsCompletePlace())
              {
                this.words.forEach((w) => {
                  if (w.id===this.currentWord.id)
                  {
                    w.quiz.isUserAnswer=true;

                    if (this.checkUserAnswer())
                    {
                      this.winPlayer.play()
                    }
                    else
                    {
                      this.losePlayer.play()
                    }
                  }
                });

                this.setQuizResultWord()

				if (this.checkEndGame())
				{
					this.sendQuizToServer()
				}
				else
				{
					if (this.currentWord.id===this.words[(this.words.length)-1].id)
					{
						this.alertText='برای ثبت امتیاز لطفا برای همه کلمات پاسخ ثبت کنید'
						this.showAwardAlertModal.show()
					}
				}
              }
            }
          }
        });
      }

    },

	sendQuizToServer(){

		if(!this.sendScore && this.videoAdvertisementWatched){
			let that = this;
			let params = {
				"subGroupId": that.subGroup.subGroupId,
				"words": that.words
			};

			that.sendQuizLoading = true;

			new API_USER().setSpellQuiz(params).then(result => {
				that.sendQuizLoading = false;

				this.alertText=result.general.text;
				this.showAwardAlertModal.show();

				this.sendScore=true;
			});
		}

	},

	checkUserAnswer()
    {
      let output=true;

      let currentWordArray=this.wordToChar(this.currentWord.en)

      for (let i = 0; i < this.answerArray.length; i++)
      {
        if (this.answerArray[i].char !== currentWordArray[i])
        {
          output=false;
        }
      }

      return output;
    },

    answerCharClick(clickedCharIndex){

      if (!this.currentWord.quiz.isUserAnswer){
        this.answerArray.forEach((aItem,index) => {
          if (clickedCharIndex===index)
          {
            if (aItem.full)
            {
              let questionIndex=aItem.qIndex;

              aItem.char='';
              aItem.full=false;
              aItem.qIndex='';

              this.quizArray.forEach((qItem,index) => {
                if (questionIndex===index)
                {
                  qItem.use=false;
                }
              });
            }
          }
        });
      }
    },

    setQuizResultWord(){

      let correctWordChars=this.wordToChar(this.currentWord.en);

      this.answerArray.forEach((aItem,index) => {
        if (aItem.char===correctWordChars[index])
        {
          aItem.isCorrect=true;
        }
      });

      this.words.forEach((w) => {
        if (w.id===this.currentWord.id)
        {
          w.quiz.quizResult=this.answerArray;

			w.quiz.spellCheck= !(w.quiz.quizResult.some(qrI => !qrI.isCorrect));

        }
      });

    },

    answerArrayIsCompletePlace(){
      for (let i = 0; i < this.answerArray.length; i++)
      {
        if (this.answerArray[i].full===false)
        {
          return false;
        }
      }

      return true;
    },

    wordToChar(word){

      let lowerCaseWord=word.toLowerCase();
      let outputCharArray=[];

      for (let i = 0; i < lowerCaseWord.length; i++)
      {
        outputCharArray.push(lowerCaseWord.charAt(i))
      }

      return outputCharArray;
    },

    randomChangeArrayItems(array) {
      for (let i = array.length - 1; i > 0; i--)
      {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // تعویض مقادیر array[i] و array[j]
      }
      return array;
    },

    addThreeRandomCharToWordArray(array) {
      const letters = 'abcdefghijklmnopqrstuvwxyz';
      for (let i = 0; i < 3; i++) {
        const randomLetter = letters[Math.floor(Math.random() * letters.length)];
        array.push(randomLetter);
      }

      console.log(array)
      return array;
    },

    checkEndGame() {

      let end = true;
      this.words.forEach((wordItem) => {

        if (wordItem.quiz.isUserAnswer === false) {
          end = false;
        }
      });

      return end;
    },

    getCurrentWordIndex(current) {

      let placeNumber = 0
      this.words.forEach((item, index) => {
        if (current.id === item.id) {
          placeNumber = index;
        }
      });

      return placeNumber;

    },

	goNextWord(){
		let currentIndex=this.getCurrentWordIndex(this.currentWord);

		let midLength=Math.floor((this.words.length)/2)
		if (currentIndex+2===midLength)
		{
			//TODO:Call Video advertisement method in Android
			// eslint-disable-next-line no-undef
			androidListener.showVideoAds("SetGameScore");
		}
		if(currentIndex+1<this.words.length)
		{
		this.setCurrentWord(currentIndex+1)
		}
	},

	goBackWord(){
		let currentIndex=this.getCurrentWordIndex(this.currentWord);

		let midLength=Math.floor((this.words.length)/2)

		if (currentIndex===midLength)
		{
			if (currentIndex===midLength)
			{
				//TODO:Call Video advertisement method in Android
				// eslint-disable-next-line no-undef
				androidListener.showVideoAds("SetGameScore");
			}
		}
		if(currentIndex-1>=0)
		{
		this.setCurrentWord(currentIndex-1)
		}
	},

    playSpeech(word) {

		//TODO:Call Speech
		// eslint-disable-next-line no-undef
		androidListener.speechWord(word);
      /*let that = this;
      that.activeSpeech = true;


      const msg = new SpeechSynthesisUtterance();
      msg.text = word;
      msg.volume = 1.0; // speech volume (default: 1.0)
      msg.pitch = 1.0; // speech pitch (default: 1.0)
      msg.rate = 1.0; // speech rate (default: 1.0)
      msg.lang = 'en-US'; // speech language (default: 'en-US')
      msg.voiceURI = 'Google UK English Female'; // voice URI (default: platform-dependent)
      msg.onboundary = function (e) {
        e.preventDefault()
      };
      msg.onpause = function (e) {
        e.preventDefault()
      };
      msg.onend = function (e) {
        e.preventDefault();
        that.activeSpeech = false;
      };

      window.speechSynthesis.speak(msg);*/

    },

    saveWord(wordId) {
		this.words.forEach((item) => {
			if (wordId===item.id)
			{
				item.isSave=!item.isSave;

				let params = {
				"wordId": wordId
				};

				new API_SAVE().changeStatus(params).then(result => {
					console.log(result)
				});
			}
		});
    },

  },

  data() {
    return {
		videoAdvertisementWatched:false,
		filterBannerShow:false,
		sendScore:false,
		sendQuizLoading:false,
		showLoading:true,
		words:[],
		subGroup:'',
		quizArray:[],
		answerArray:[],
		winPlayer: '',
		losePlayer: '',
		alertText: '',
		showAwardAlertModal: '',
		currentWord: '',
		dataIsLoad: false,
		activeSpeech: false,
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.keyboard-game-content {

  .items {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .up {

      .box-title {
        display: flex;
        margin: 12px 0;
        align-items: center;
        flex-direction: row;


        span {
          color: $name-box-color;
          font-weight: bold;
          font-size: $normal-font-size+2;
          min-width: fit-content;
        }

        .roll {
          background-color: $roll-box-color;
          width: 100%;
          height: 1px;
          margin-inline: 10px;
        }
      }

      .show-word {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: $background-color-2;
        border-radius: 5px;
        border: 1px solid $color-number;

        .roll {
          width: 90%;
          margin: 5px;
          display: flex;
          align-self: center;
          height: 1px;
          background-color: $roll-box-color;
        }

        .title {
          margin-top: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .name {
            color: $text-color;
            font-weight: bold;
            font-size: $large-font-size;
          }

          .roll {
            width: 90%;
            margin-top: 15px;
            height: 1px;
            background-color: $roll-box-color;
          }

          .mean {
            color: $name-box-color;
            font-size: $small-font-size;
            margin-top: 15px;
          }

        }

        .control-btn {
          margin: 20px 0;
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .b-icon {
            width: 40px;
            display: flex;
            cursor: pointer;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 70px;
            font-size: $small-font-size;
            padding: 10px;
            background-color: $background-color;
            margin: 10px;
            color: $key-color;

            &.active {
              color: $text-color;
            }
          }

        }

        .keyword {
          width: 100%;
          display: flex;
          margin-top: 15px;
          align-items: center;
          justify-content: center;
          font-size: $normal-font-size;
          font-weight: bold;

          .key {
            justify-content: flex-start;
            width: 90%;
            flex-wrap: wrap;
            display: flex;

            span {
              width: 43px;
              height: 43px;
              display: flex;
              margin-inline-end: 10px;
              cursor: pointer;
              user-select: none;
              color: $text-color;
              align-items: center;
              justify-content: center;
              background-color: $text-color-2;
              border-radius: 5px;
              margin-bottom: 10px;

              &.not-correct {
                color: $no-key-color;
                background-color: $no-color;
              }
            }

          }
        }

      }

      .keyboard {
        margin-bottom: 7px;
        display: flex;
        margin-top: 15px;
        align-items: center;
        justify-items: center;
        border-radius: 10px;
        padding: 20px;
        background-color: $text-color-2;
        justify-content: flex-start;
        font-size: $normal-font-size;
        font-weight: bold;

        .key {
          width: 100%;
          flex-wrap: wrap;
          justify-content: flex-start;
          display: flex;
          align-content: flex-start;

          span {
            width: 43px;
            height: 43px;
            display: flex;
            margin-inline-end: 10px;
            color: $background-color;
            align-items: center;
            justify-content: center;
            background-color: $key-color;
            border-radius: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            user-select: none;

            &.active {
              color: $background-color;
              background-color: $text-color;
            }
          }

        }
      }
    }

	.down
	{
		.button {
			justify-content: center;
			display: flex;
			width: 100%;

			.end-items {
				width: 100%;


			.next-bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.b-icon {
			width: 50px;
			height: 50px;
			display: flex;
			color: $text-color;
			align-items: center;
			justify-content: center;
			border-radius: 100px;
			padding: 15px;
			font-size: $super-extra-font-size;
			background-color: $text-color-2;
			}

			.left {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;


				span {
					font-size: $small-font-size;
					margin-block-start: 5px;
					color: $text-color;
					margin-block: 10px;
					margin-inline: 10px;

				}
			}

			.center {
			background-color: $reward-color;
			display: flex;
			height: 40px;
			padding: 5px;
			border-radius: 30px;
			flex: 1;
			align-items: center;
			font-size: $small-font-size;
			justify-content: center;
			flex-direction: row;
			margin-inline: 10px;

			.left {
				margin-inline: 10px;
				color: $name-box-color;

				.count {
				color: $text-color;
				}
			}

			.right {
				margin-inline: 10px;
				color: $text-color;

				.i-icon {
				margin-inline: 5px;
				font-size: $small-icon-size;
				}
			}
			}

			.right {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			}
			}
			}
		}
	}

  }

}
</style>