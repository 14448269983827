<template>

  <div class="home-page-skeleton wrapper-content">

    <div class="g-content-items">
      <div class="skeleton g-c-item" v-for="(ev,index) in 6" :key="index"></div>
    </div>

  </div>

</template>

<script>

export default
{

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/style";

.home-page-skeleton
{
  display: flex;
  flex-direction: column;
  direction: rtl;

  .sub-g-title
  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .r{
      width: 20%;
      height: 10px;
      border-radius: 3px;
    }

    .l{
      width: 76%;
      height: 10px;
      border-radius: 3px;
    }
  }

  .sub-g-content-items
  {
    display: flex;
    margin-bottom: 30px;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar
    {
      display: none;
    }

    .s-g-c-item
    {
      height: 200px;
      min-width: 280px;
      border-radius: 5px;
      margin: 2px;
    }
  }

  .g-title
  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .r{
      width: 20%;
      height: 10px;
      border-radius: 3px;
    }

    .l{
      width: 76%;
      height: 10px;
      border-radius: 3px;
    }
  }

  .g-content-items
  {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .g-c-item
    {
      height: 60px;
      width: 48%;
      margin: 2px;
      border-radius: 5px;
    }
  }
}


</style>