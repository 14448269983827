<template>
  <div class="main-content">
    <div class="sub-page-content">
      <router-view/>
    </div>

    <AppMenu/>

  </div>
</template>

<script>
import AppMenu from "@/components/AppMenu.vue";

export default
{
  components: {AppMenu},
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";
.main-content{
  display: flex;
  flex-direction: column;
  height: 100dvh;
  .sub-page-content
  {
    flex: 1;
    align-items: center;
    padding-bottom: 60px;
    padding-top: 70px;
  }
}

</style>