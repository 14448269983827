import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from "@/router/router";
import mitt from "mitt";

import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.min.css';


/* import the fontawesome */
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faCopy as faCopySolid,
    faCalendarDays as faCalendarDaysSolid,
    faCheck as faCheckSolid,
    faCircleChevronRight as faCircleChevronRightSolid,
    faCircleChevronLeft as faCircleChevronLeftSolid,
    faCommentDots as faCommentDotsSolid,
    faEllipsisVertical as faEllipsisVerticalSolid,
    faGlobe as faGlobeSolid,
    faHouse as faHouseSolid,
    faUser as faUserSolid,
    faBell as faBellSolid,
    faGear as faGearSolid,
    faHeart as faHeartSolid,
    faUserSecret as faUserSecretSolid,
    faWallet as faWalletSolid,
    faPaperPlane as faPaperPlaneSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faAngleRight,
    faGem,
    faList as faListSolid,
    faCheckToSlot as faCheckToSlotSolid,
    faPlay as faPlaySolid,
    faEyeSlash as faEyeSlashSolid,

    faPuzzlePiece,
    faDice,
    faAngleLeft,
    faBookmark,
    faBorderAll, faTimes, faUser,
} from '@fortawesome/free-solid-svg-icons'

import {
    faFile as faFileRegular,
    faCalendar as faCalendarRegular,
    faComment as faCommentRegular,
    faCircleQuestion as faCircleQuestionRegular,
    faClock as faClockRegular,
    faComments as faCommentsRegular,
    faMap as faMapRegular,
} from '@fortawesome/free-regular-svg-icons'
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import axios from "axios";

library.add(
    faCopySolid,
    faEyeSlashSolid,
    faPlaySolid,
    faListSolid,
    faCheckToSlotSolid,
    faUserSecretSolid,
    faHeartSolid,
    faCircleChevronLeftSolid,
    faCircleChevronRightSolid,
    faHouseSolid,
    faCalendarDaysSolid,
    faCommentDotsSolid,
    faPaperPlaneSolid,
    faGlobeSolid,
    faMagnifyingGlassSolid,
    faEllipsisVerticalSolid,
    faUserSolid, faCheckSolid,
    faBellSolid,
    faGearSolid,
    faWalletSolid,
    faFileRegular,
    faCommentRegular,
    faCalendarRegular,
    faCircleQuestionRegular,
    faClockRegular,

    faCommentsRegular,
    faTimes,
    faUser,
    faAngleRight,
    faAngleLeft,
    faGem,
    faBookmark,
    faBorderAll,
    faDice,
  faSquare,
    faPuzzlePiece,
    faMapRegular
)


const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.axios=axios
app.config.globalProperties.emitter = mitt();
app.use(router);
app.mount('body')
