<template>
  <div class="word-en-game-content ">
	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left ">

				</div>
				<div class="right">
				<div class="icon">
				<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
				</div>
				</div>
			</div>
		</div>
	</div>

    <EnWordGamePageSkeleton v-if="showLoading"/>

    <div class="items wrapper-content" v-if="!showLoading">
      <div class="up">
        <div class="box-title">
          <div class="roll"></div>
          <span>{{subGroup.fa}}</span>
        </div>
        <div class="show-word">

          <div class="title">
            <span class="name">{{currentWord.fa}}</span>
          </div>

          <div class="control-btn">
			<font-awesome-icon class="b-icon" :class="{active: currentWord.isSave}" icon="fa-solid fa-bookmark" @click="saveWord(currentWord.id)"/>
          </div>

          <div class="roll"></div>

          <div class="choose">

            <div class="key" v-for="(quizItem,index) in currentWord.quiz.items" :key="index" @click="userSelectAnswer(index)">
				<span
					:class="(currentWord.quiz.isUserAnswer && quizItem.userChecked &&  quizItem.isAnswer)
					?'user-selected-true'
					:(currentWord.quiz.isUserAnswer && quizItem.userChecked && !quizItem.isAnswer)
					?'user-selected-false'
					:(currentWord.quiz.isUserAnswer && !quizItem.userChecked && quizItem.isAnswer)
					?'user-selected-true':''
					">
					{{quizItem.word}}
              </span>

            </div>
          </div>
        </div>
      </div>
      <div class="down">
        <div class="button">
          <div class="end-items">
			<div class="next-bar">
				<div class="left" @click="goBackWord">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
					</div>
				</div>
				<div class="center">
					<div class="left">
						<p>{{words.length}}</p>
						<p class="count"> / {{getCurrentWordIndex(currentWord)+1}}</p>
					</div>
				</div>
				<div class="right" @click="goNextWord">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right"/>
					</div>
				</div>
			</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <show-alert-modal
    :item="{
    text:alertText,
    btnTitle:'بله . متوجه شدم'
  }"
  :on-ok-click="()=>{showAwardAlertModal.hide()}"/>

  <audio controls id="win-player" class="d-none">
    <source src="../assets/sounds/win.mp3" type="audio/mpeg">
  </audio>

  <audio controls id="lose-player" class="d-none">
    <source src="../assets/sounds/lose.mp3" type="audio/mpeg">
  </audio>

	<LoadingFullScreen :show="sendQuizLoading"/>

	<FilterBanner :show="filterBannerShow" :on-btn-click="changeFilterBanner"/>


</template>

<script>
import {Modal} from "bootstrap";
import EnWordGamePageSkeleton from "@/components/skeleton/EnWordGamePageSkeleton.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import API_WORD from "@/api/API_WORD";
import API_SAVE from "@/api/API_SAVE";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
import API_USER from "@/api/API_USER";
import FilterBanner from "@/components/FilterBanner.vue";


export default {
  components: {FilterBanner, LoadingFullScreen, ShowAlertModal, EnWordGamePageSkeleton},
  mounted() {

    this.winPlayer=document.querySelector("#win-player");
    this.losePlayer=document.querySelector("#lose-player");

    this.showAwardAlertModal = new Modal(document.getElementById('show-alert-modal'));

	this.getWords(this.$route.params.group);

	//Android Custom Event
	document.addEventListener('SetGameScoreMethod', this.SetGameScoreMethod);
	document.addEventListener('showFilterBannerMethod', this.showFilterBannerMethod);
  },

  methods:{

	showFilterBannerMethod(){
		this.filterBannerShow=true;
	},

	changeFilterBanner(show){
		this.filterBannerShow=show;
	},

	SetGameScoreMethod(){
		this.videoAdvertisementWatched=true;
	},


	getWords(groupId){
		let that = this;
		let params = {
		"subGroupId": groupId
		};

		that.showLoading = true;

		new API_WORD().getWordsWithEnQuiz(params).then(result => {
			that.showLoading = false;

			if (result.general.status) {
				that.words = result.data.words;
				that.subGroup = result.data.subGroup;
				this.setCurrentWord(0);
			}
		});
	},

	setCurrentWord(arrayIndex){
		this.currentWord=this.words[arrayIndex];
	},

    userSelectAnswer(userAnswerIndex){
      this.currentWord.quiz.items.forEach((quizItem,index) => {

        if(userAnswerIndex===index)
        {
          if(!this.currentWord.quiz.isUserAnswer)
          {
            quizItem.userChecked=true;

            this.currentWord.quiz.isUserAnswer=true;

            if (quizItem.isAnswer===true)
            {
              this.winPlayer.play();
            }
            else
            {
              this.losePlayer.play();
            }

			if (this.endGame())
			{
				this.sendQuizToServer()
			}
			else
			{
				if (this.currentWord.id===this.words[(this.words.length)-1].id)
				{
					this.alertText='برای ثبت امتیاز لطفا برای همه کلمات پاسخ ثبت کنید'
					this.showAwardAlertModal.show()
				}

			}
          }
        }
      });


    },

	sendQuizToServer(){

		if(!this.sendScore && this.videoAdvertisementWatched){
			let that = this;
			let params = {
				"subGroupId": that.subGroup.subGroupId,
				"words": that.words
			};

			that.sendQuizLoading = true;

			new API_USER().setEnQuiz(params).then(result => {
				that.sendQuizLoading = false;

				this.alertText=result.general.text;
				this.showAwardAlertModal.show();

				this.sendScore=true;
			});
		}
	},

    endGame(){
		return !this.words.some(wordItem => !wordItem.quiz.isUserAnswer);
    },

    getCurrentWordIndex(current){

		let placeNumber=0
		this.words.forEach((item,index) => {
			if (current.id===item.id)
			{
				placeNumber= index;
			}
		});

		return placeNumber;

    },

    goNextWord(){
		let currentIndex=this.getCurrentWordIndex(this.currentWord);

		let midLength=Math.floor((this.words.length)/2)
		if (currentIndex+2===midLength)
		{
			//TODO:Call Video advertisement method in Android
			// eslint-disable-next-line no-undef
			androidListener.showVideoAds("SetGameScore");
		}

		if(currentIndex+1<this.words.length)
		{
			this.setCurrentWord(currentIndex+1)
		}
    },

    goBackWord(){
		let currentIndex=this.getCurrentWordIndex(this.currentWord);
		let midLength=Math.floor((this.words.length)/2)

		if (currentIndex===midLength)
		{
			//TODO:Call Video advertisement method in Android
			// eslint-disable-next-line no-undef
			androidListener.showVideoAds("SetGameScore");
		}

		if(currentIndex-1>=0)
		{
			this.setCurrentWord(currentIndex-1)
		}
    },

	saveWord(wordId)
	{
		this.words.forEach((item) => {
			if (wordId===item.id)
			{
				item.isSave=!item.isSave;

				let params = {
				"wordId": wordId
				};

				new API_SAVE().changeStatus(params).then(result => {
				console.log(result)
				});
			}
		});
		},
  },

  data(){
    return{
		videoAdvertisementWatched:false,
		filterBannerShow:false,
		sendQuizLoading:false,
		words:[],
		subGroup:'',
		showLoading:true,
		winPlayer:'',
		losePlayer:'',
		alertText:'',
		showAwardAlertModal:'',
		currentWord:'',
		sendScore:false,
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.word-en-game-content {

  .items {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .up
    {

      .box-title {
        display: flex;
        margin: 12px 0;
        align-items: center;
        flex-direction: row;


        span {
          color: $name-box-color;
          font-weight: bold;
          font-size: $normal-font-size+2;
          min-width: fit-content;
        }

        .roll {
          background-color: $roll-box-color;
          width: 100%;
          height: 1px;
          margin-inline: 10px;
        }
      }

      .show-word {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: $background-color-2;
        border-radius: 5px;
        border:1px solid $color-number;

        .roll {
          width: 90%;
          margin: 5px;
          display: flex;
          align-self: center;
          height: 1px;
          background-color: $roll-box-color;
        }

        .title {
          margin-top: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .name {
            color: $text-color;
            font-weight: bold;
            font-size: $large-font-size;
          }

          .roll {
            width: 90%;
            margin-top: 15px;
            height: 1px;
            background-color: $roll-box-color;
          }

          .mean {
            color: $name-box-color;
            font-size: $small-font-size;
            margin-top: 15px;
          }

        }

        .control-btn {
          margin: 20px 0;
          flex-direction: row;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          .b-icon {
            width: 40px;
            display: flex;
            cursor: pointer;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 70px;
            font-size: $small-font-size;
            padding: 10px;
            background-color: $background-color;
            margin: 10px;
            color: $key-color;

            &.active
            {
              color: $text-color;
            }
          }

        }

        .choose {
          width: 100%;
          display: flex;
          margin-top: 2px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          .roll {
            margin-top: 0;
            margin-bottom: 20px !important;
          }

          .key {
            justify-content: center;
            align-items: center;
            width: 90%;
            flex-direction: column;
            display: flex;

            span {
              width: 90%;
              height: 50px;
              display: flex;
              font-weight: bold;
              font-size: $normal-font-size;
              color: $name-box-color;
              align-items: center;
              justify-content: center;
              background-color: $text-color-2;
              border-radius: 5px;
              margin-bottom: 10px;

              &.user-selected-true{
                color: $no-key-color;
                background-color: $text-color;
              }

              &.user-selected-false{
                color: $no-key-color;
                background-color: $no-color;
              }

            }

          }
        }
      }
    }

    .down
    {
      .button {
        justify-content: center;
        display: flex;
        width: 100%;

        .end-items {
			width: 100%;


			.next-bar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

				.b-icon {
				width: 50px;
				height: 50px;
				display: flex;
				color: $text-color;
				align-items: center;
				justify-content: center;
				border-radius: 100px;
				padding: 15px;
				font-size: $super-extra-font-size;
				background-color: $text-color-2;
				}

				.left {
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;


					span {
					font-size: $small-font-size;
					margin-block-start: 5px;
					color: $text-color;
					margin-block: 10px;
					margin-inline: 10px;

					}
				}

				.center {
				background-color: $reward-color;
				display: flex;
				height: 40px;
				padding: 5px;
				border-radius: 30px;
				flex: 1;
				align-items: center;
				font-size: $small-font-size;
				justify-content: center;
				flex-direction: row;
				margin-inline: 10px;

				.left {
					margin-inline: 10px;
					color: $name-box-color;

					.count {
					color: $text-color;
					}
				}

					.right {
						margin-inline: 10px;
						color: $text-color;

					.i-icon {
						margin-inline: 5px;
						font-size: $small-icon-size;
						}
					}
				}

				.right {
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
        }
      }
    }

  }

}
</style>