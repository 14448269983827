<template>
	<div class="install-area" v-if="shown">
		<button @click="installPWA">Install</button>
	</div>
  <router-view v-if="!shown"/>
</template>

<script>
import {GLOBAL} from "@/incloude/global";


export default {
	data: () => ({
		shown: false,
	}),

	beforeMount() {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault()
			this.installEvent = e
			this.shown = true
		})
	},

	mounted() {
		GLOBAL.router = this.$router;
	},

	methods: {
		dismissPrompt() {
			this.shown = false
		},
		pageRoute(goto){
			this.$router.push({name: goto})
		},
		pageRouteWithParam(name,param){
			this.$router.push({name: name, params: param});
		},
		pageRouteBack(){
			this.$router.go(-1)
		},

		installPWA() {
			this.installEvent.prompt()
			this.installEvent.userChoice.then((choice) => {
				this.dismissPrompt() // Hide the prompt once the user's clicked
				if (choice.outcome === 'accepted') {
					// Do something additional if the user chose to install
				} else {
					// Do something additional if the user declined
				}
			})
		},
	}
}
</script>

<style lang="scss">
.install-area
{
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	button
	{
		color: #fff;
		background: transparent;
		font-size: 50px;
	}
}
</style>
