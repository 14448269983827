<template>
  <div class="withdraw-list-skeleton wrapper-content">
    <div class="item skeleton" v-for="(item) in 5 " :key="item"></div>
  </div>
</template>

<script>

export default
{

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/style";

.withdraw-list-skeleton
{
  display: flex;
  flex-direction: column;
  justify-content: center;

  .item
  {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}


</style>