import API from "@/api/API";

export default class API_WITHDRAW extends API {

    setWithdraw=(params) => {
        return this.post("Withdraw/SetWithdraw",params);
    }

    withdrawList=(params) => {
        return this.post("Withdraw/WithdrawList",params);
    }
}