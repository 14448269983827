<template>

  <div class="vertical-list wrapper-content">
    <div class="items">
      <div class="item-group" :class="{'active':(item.active)}" v-for="(item,index) in items" :key="index" @click="onGroupClick(item)">
        <div class="name-group">
          <span>{{ item.fa }}</span>
        </div>
        <div class="details">
          <div class="item-left"><span>{{new Intl.NumberFormat('en-US').format(item.totalScore*4)}}</span>
            <font-awesome-icon class="b-icon" icon="fa-solid fa-gem"/>
          </div>
          <div class="item-right">
            <span>{{ item.totalSubGroup }}</span>
            <font-awesome-icon class="b-icon" icon="fa-solid fa-globe"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    onGroupClick: {
      type: Function,
      required: true,
    },

  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.vertical-list {
  display: flex;
  flex-direction: row;

  .items {
    width: 100%;
    align-items: start;
    margin: 0;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row-reverse;

    &::-webkit-scrollbar {
      display: none;
    }

    .item-group {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: flex-end;
      color: $name-box-color;
      font-size: $small-font-size;
      background-color: $background-color-2;
      border:1px solid $color-number;
      border-radius: 5px;
      width: 47%;
      padding: 10px;
      margin: 3px;

      .name-group {
        margin-bottom: 5px;
      }

      &.active {

        border: 1px solid $text-color;
        color: $text-color;
        .b-icon
        {
          color: $text-color !important;
        }
      }

      .details {
        flex-direction: row;
        display: flex;
        margin-top: 10px;
        width: 100%;
        justify-content: space-between;

        .b-icon {
          color: $roll-box-color;
          margin-inline-start: 5px;
          font-size: $small-font-size;

        }
      }
    }
  }
}

</style>