<template>
  <div class="advertisement-content wrapper-content">

  </div>
</template>

<script>
export default {
  mounted() {
	this.$root.pageRouteBack();
  },

  methods: {

  },

  data() {
    return {
    }
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.advertisement-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

	.timer {
		align-items: center;
		display: flex;
		color: #FFEE43;
		margin-bottom: 10px;
	}


}
</style>