<template>
  <show-alert-modal
    :item="{
      text:this.withdrawAlertText,
      btnTitle:'بله متوجه شدم'
    }"
    :on-ok-click="()=>this.showAlertModal.hide()"
  />

	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left "></div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>

  <div class="withdrawal-list wrapper-content">
    <div class="box-title">
      <div class="roll"></div>
      <span>ثبت برداشت جدید</span>
    </div>
    <div class="content-withdrawal">
      <div class="items">
        <div class="i-input ">
          <span>مبلغ(تومان)</span>
          <input type="number" v-model="price">
          <span class="price-hinter">تومان {{new Intl.NumberFormat('en-US').format(price)}}</span>
        </div>
        <div class="i-input ">
          <span>شماره کارت بانکی</span>
          <input type="number" v-model="bankAccount">
        </div>
        <div class="buttons">
          <div class="btn-success">
            <span @click="registerWithdraw">ثبت برداشت</span>
          </div>

        </div>
      </div>
    </div>
  </div>

	<LoadingFullScreen :show="showLoading"/>

</template>

<script>

import {Modal} from "bootstrap";
import WithdrawalModal from "@/components/WithdrawalModal.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import API_USER from "@/api/API_USER";
import API_WITHDRAW from "@/api/API_WITHDRAW";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
export default {
  components: {LoadingFullScreen, ShowAlertModal},

  mounted() {
    this.showAlertModal = new Modal(document.getElementById('show-alert-modal'));
  },

  methods: {
   registerWithdraw() {
		let params={
			"price":this.price,
			"cartNumber":this.bankAccount
		};
		this.showLoading=true;

		new API_WITHDRAW().setWithdraw(params).then(result => {

			this.showLoading=false;


			if(result.general.status)
			{
				this.withdrawAlertText=result.general.text;
				this.showAlertModal.show();
			}
			else
			{
				this.withdrawAlertText=result.general.text;
				this.showAlertModal.show();
			}
		});
    },
  },

  data() {
    return {
		showLoading:false,
		withdrawAlertText:'',
		showAlertModal: null,
		price:'',
		bankAccount:''
    }
  },

}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.withdrawal-list {
  display: flex;
  flex-direction: column;
  margin: 0;

  .box-title {
    display: flex;
    margin: 5px 0;
    align-items: center;
    flex-direction: row;
    margin-inline: 5%;

    span {
      color: $name-box-color;
      font-weight: bold;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;
      font-size: $normal-font-size+2;
    }

    .roll {
      background-color: $roll-box-color;
      width: 100%;
      height: 1px;
      flex: 1;
      margin-inline: 10px;
    }
  }
.content-withdrawal
{
  align-items: center;
  display: flex;
  justify-content: center;
  .items {
    align-items: center;
    margin-inline: 10px;

    margin: 10px 0;
    width: 90%;
    overflow-x: auto;
    border-radius: 5px;
    padding: 10px;
    overflow-y: hidden;
    display: flex;
    background-color: $reward-color;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }

    .i-input {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      width: 100%;
      flex-direction: column;

      span {
        color: $name-box-color;
        margin-bottom: 5px;
        font-size: $small-font-size;
      }

      .price-hinter
      {
        direction: rtl;
        font-size: $small-font-size - 2;
        color: $yel-color;
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
      }

      input {
        display: flex;
        height: 40px;
        width: 100%;
        outline: none;
        color: $text-color;
        padding-inline: 10px;
        font-size: $normal-font-size;
        background-color: $background-color-2;
        border-radius: 4px;
        border: 1px solid $text-color-2;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      justify-content: center;

      .btn-success {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          background-color: $text-color;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $background-color;
          font-size: $small-font-size;
          width: 100%;
          font-weight: 700;
          border-radius: 5px;
        }
      }

    }
  }
}

}

</style>