<template>
  <div id="out-account-modal" class="modal fade out-account-modal" tabindex="-1" aria-labelledby="staticBackdropLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content s-t-content">
        <div class="modal-body s-t-body">
          <h2>آیا میخواهید از اپلیکیشن خارج شوید ؟</h2>
          <div class="buttons">
            <div class="btn-success" @click="onOkClick">
              <span>بله .من رفتم</span>
            </div>
            <div class="btn-null" @click="onCancelClick">
              <span>خیر . من موندنی ام</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    onOkClick: {
      type: Function,
      required:true,
    },
    onCancelClick: {
      type: Function,
      required:true,
    },

  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/scss/style";

.out-account-modal {
  .s-t-content {
    background-color: $reward-color;
    border-radius: 10px;


    .s-t-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;
      h2 {
        color: $name-box-color;
        margin: 20px 0;
        font-size: $normal-font-size;
        text-align: center;
        line-height: 25px;
      }


      .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .btn-success {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          span {
            background-color: $text-color;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $background-color;
            font-size: $small-font-size;
            width: 100%;
            font-weight: 700;
            border-radius: 5px;
          }
        }

        .btn-null {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          span {
            background-color: $background-color;
            height: 40px;
            border: 1px solid $name-box-color;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $name-box-color;
            font-size: $small-font-size;
            width: 100%;
            font-weight: 700;
            border-radius: 5px;

          }
        }
      }


    }
  }
}

</style>