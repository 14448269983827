<template>
  <div class="gem-content">
	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left ">

				</div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>

    <SavedPageSkeleton v-if="!dataIsLoad"/>

    <SaveGroupVerticalList v-if="dataIsLoad" :items="savedWordList"/>

  </div>
</template>

<script>


import SaveGroupVerticalList from "@/components/SaveGroupVerticalList.vue";
import SavedPageSkeleton from "@/components/skeleton/SavedPageSkeleton.vue";
import API_SAVE from "@/api/API_SAVE";
import {toRaw} from "vue";

export default {
  components: { SavedPageSkeleton, SaveGroupVerticalList},

  mounted: function () {
    let that=this;

	that.emitter.on("savedSubGroupItem", (item)=> {
		localStorage.setItem('savedWords',JSON.stringify(item.items));
		that.$root.pageRoute('SavedWordReview')
    });

	this.getSavedWords()


  },
  methods: {
	getSavedWords(){
		this.dataIsLoad=false;
		new API_SAVE().getSavedWords().then(result => {
			this.dataIsLoad=true;

		if (result.general.status) {
			this.savedWordList=this.categorizeItems(result.data.words)
		}
		});
	},
	categorizeItems(items) {
		const mainGroups = {};
		items.forEach((item) => {
			const mainGroupId = item.word.subGroup.mainGroup.id;
			const subGroupId = item.word.subGroup.subGroupId;
			// ایجاد گروه اصلی اگر وجود ندارد
			if (!mainGroups[mainGroupId]) {
				mainGroups[mainGroupId] = {
					mainGroup: item.word.subGroup.mainGroup,
					subGroups: {}
				};
			}

			// ایجاد گروه فرعی اگر وجود ندارد
			if (!mainGroups[mainGroupId].subGroups[subGroupId]) {
				mainGroups[mainGroupId].subGroups[subGroupId] = {
					subGroup: item.word.subGroup,
					items: []
				};
			}

			// افزودن آیتم به گروه فرعی مربوطه
			mainGroups[mainGroupId].subGroups[subGroupId].items.push(item.word);
		});

		return mainGroups;
	},

  },
  data() {
    return {
		dataIsLoad: false,
		empty: false,
		savedWordList: null,
    }
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


</style>