<template>

  <div class="horizontal-list">
    <div class="box-title">
      <div class="roll"></div>
      <span>{{groupTitle}}</span>
    </div>
    <div class="items">

      <div class="item-word" :class="{'de-active':!(item.opened)}" v-for="(item,index) in items" :key="index" @click="onSubGroupClick(item)">
        <span class="name">{{ item.groupTitle }}</span>
        <div class="detail">
          <span>
            <font-awesome-icon class="b-icon" icon="fa-solid fa-gem"/>
			{{ item.score*4 }}
          </span>
          <span>{{ item.fa }}
          </span>
        </div>
        <div class="img" :style="{ backgroundColor: getRandomColor() }">
          <img :src="item.imageUrl" :alt="item.groupTitle">
        </div>
        <div class="btn">
	        <div class="btn-item">
		        <font-awesome-icon class="b-icon" icon="fa-solid fa-puzzle-piece" @click="$root.pageRouteWithParam('KeyboardGame',{group:item.subGroupId})"/>
		        <span>املا</span>
	        </div>
	        <div class="btn-item">
		        <font-awesome-icon class="b-icon" icon="fa-solid fa-check-to-slot"  @click="$root.pageRouteWithParam('EnWordGame',{group:item.subGroupId})"/>
		        <span>انگلیسی</span>
	        </div>
	        <div class="btn-item">
		        <font-awesome-icon class="b-icon" icon="fa-solid fa-list" @click="$root.pageRouteWithParam('FaWordGame',{group:item.subGroupId})"/>
		        <span>فارسی</span>
	        </div>
	        <div class="btn-item">
		        <font-awesome-icon class="b-icon" icon="fa-solid fa-comment-dots" @click="$root.pageRouteWithParam('WordReview',{group:item.subGroupId})"/>
		        <span>مرور</span>
	        </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

export default
{
  props:{
    groupTitle:{
      type:String,
      required:true
    },
    items:{
      type:Array,
      required:true,
    },
    onSubGroupClick:{
      type:Function ,
      required:true,
    },

  },

  data(){
    return{

    }
  },

  methods:{
	getRandomColor() {
		let letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}
  },

}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.horizontal-list
{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;


  .box-title {
    display: flex;
    margin: 5px 0;
    align-items: center;
    flex-direction: row;
    margin-inline:5%;

    span {
      color: $name-box-color;
      font-weight: bold;
      font-size: $normal-font-size+2;
      white-space: nowrap;
    }

    .roll {
      background-color: $roll-box-color;
      width: 100%;
      height: 1px;
      margin-inline: 10px;
    }
  }

  .items
  {
    width: 100%;
    align-items: start;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row-reverse;
    &::-webkit-scrollbar
    {
      display: none;
    }

    .item-word {

      min-width: 350px;
      margin-inline-end:15px ;
      display: flex;
      flex-direction: column;
      background-color: $background-color-2;
      align-items: flex-end;
      border-radius: 5px;
      padding: 10px;
      border:1px solid $color-number;

      &.de-active
      {
        .name {

        }

        .detail {

        }

        .img {

          opacity: 0.1;

          img {

          }
        }

        .btn {
          opacity: 0.1;


        }
      }

      .name {
        color: $text-color;
        font-size: $small-font-size;
      }

      .detail {
        width: 100%;
        justify-content: space-between;
        display: flex;
        margin: 10px 0;
        flex-direction: row;
        color: $name-box-color;
        font-size: $very-small-font-size;
      }

      .img {
        width: 100%;
		height: 160px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;

        img {
			max-width: 90%;
			max-height: 90%;
			vertical-align: middle;
			border-radius: 10px;
			object-fit: cover;
        }
      }

      .btn {
        display: flex;
        width: 100%;
        margin-top: 5px;
        justify-content: space-between;
        flex-direction: row;

	      .btn-item{
		      display: flex;
		      flex-direction: column;
		      justify-content: center;

		      span{
			      color: $text-color;
			      font-size: $small-font-size - 3;
			      margin-top: 5px;
		      }

		      .b-icon {
			      width: 40px;
			      height: 40px;
			      display: flex;
			      color: $text-color;
			      align-items: center;
			      justify-content: center;
			      border-radius: 100px;
			      padding: 10px;
			      border: 1px solid $text-color;
			      font-size: $super-extra-font-size;
			      background-color: $background-color-2;
		      }
	      }


      }
    }

  }
}

</style>