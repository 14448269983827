<template>

  <div class="word-review-skeleton wrapper-content">
    <div class="title skeleton"></div>
    <div class="main-box skeleton"></div>
    <div class="down-box">
      <div class="d-circle skeleton"></div>
      <div class="d-counter skeleton"></div>
      <div class="d-circle skeleton"></div>
    </div>

  </div>

</template>

<script>

export default
{

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/style";

.word-review-skeleton
{
  display: flex;
  flex-direction: column;
  direction: rtl;

  .title
  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 20%;
    height: 10px;
    border-radius: 3px;

  }

  .main-box
  {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    height: 50dvh;
    border-radius: 5px;
  }

  .down-box
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;

    .d-counter
    {
      display: flex;
      flex: 1;
      height: 30px;
    }

    .d-circle
    {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      margin-inline: 5px;
    }
  }
}


</style>