<template>
	<div class="header-menus">
		<div class="sl-item">
			<div class="i-content wrapper-content">
				<div class="left "></div>
				<div class="right">
					<div class="icon">
						<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					</div>
				</div>
			</div>
		</div>
	</div>

  <div class="withdrawal-list wrapper-content">
    <div class="box-title">
      <div class="roll"></div>
      <span>لیست برداشت</span>
    </div>

    <withdraw-skeleton v-if="!dataIsLoad"/>

    <WithdrawalVerticalList v-if="dataIsLoad" :items="withdrawalList"/>
  </div>

</template>

<script>

import WithdrawalVerticalList from "@/components/WithdrawalVerticalList.vue";
import CancelWithdrawModal from "@/components/CancelWithdrawModal.vue";
import {Modal} from "bootstrap";
import WithdrawSkeleton from "@/components/skeleton/WithdrawSkeleton.vue";
import API_WITHDRAW from "@/api/API_WITHDRAW";

export default {
  components: {WithdrawSkeleton, WithdrawalVerticalList},

  mounted() {
		this.getWithdrawList();
  },

  methods: {
	getWithdrawList(){
		this.dataIsLoad=false;
		new API_WITHDRAW().withdrawList().then(result => {
			this.dataIsLoad=true;
			if(result.general.status)
			{
				this.withdrawalList=result.data.withdrawList
			}
		});
	},

  },
  data() {
    return {
		dataIsLoad:false,
		withdrawalList:null,
    }
  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.withdrawal-list {
  display: flex;
  flex-direction: column;
  margin: 0;



  .box-title {
    display: flex;
    margin: 5px 0;
    align-items: center;
    flex-direction: row;
    margin-inline: 5%;

    span {
      color: $name-box-color;
      font-weight: bold;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;
      font-size: $normal-font-size+2;
    }

    .roll {
      background-color: $roll-box-color;
      width: 100%;
      height: 1px;
      flex: 1;
      margin-inline: 10px;
    }
  }


}

</style>