import API from "@/api/API";

export default class API_USER extends API {
    login=(params) => {
        return this.post("User/Login",params);
    }

    loginVerify=(params) => {
        return this.post("User/LoginVerify",params);
    }

    openSubGroup=(params) => {
        return this.post("User/OpenSubGroup",params);
    }

    setReviewScore=(params) => {
        return this.post("User/SetReviewScore",params);
    }

    setEnQuiz=(params) => {
        return this.post("User/SetEnQuiz",params);
    }

    setFaQuiz=(params) => {
        return this.post("User/SetFaQuiz",params);
    }

    setSpellQuiz=(params) => {
        return this.post("User/SetSpellQuiz",params);
    }

    setVideoAdvertisementScore=(params) => {
        return this.post("User/SetAdvertisementScore",params);
    }

    getUserInfo=(params) => {
        return this.post("User/GetUserInfo",params);
    }

    logout=(params) => {
        return this.post("User/Logout",params);
    }

    registerReferralCode=(params) => {
        return this.post("User/RegisterReferralCode",params);
    }

    getGemTime=(params) => {
        return this.post("User/GetGemTime",params);
    }
}