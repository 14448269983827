<template>
  <div class="home-content">

    <div class="header-menus">
      <div class="sl-item">
        <div class="i-content wrapper-content">
          <div class="left ">
            <div class="icon">
              <font-awesome-icon class="b-icon" icon="fa-solid fa-gem"/>
            </div>
            <span>{{new Intl.NumberFormat('en-US').format(userInfo.totalScore)}}</span>
          </div>
          <div class="right">
            <div class="icon">
              <font-awesome-icon class="b-icon" icon="fa-solid  fa-user" @click="$root.pageRoute('Profile')"/>
            </div>
          </div>
        </div>
      </div>
    </div>

	<SubGroupSkeleton v-if="subGroupLoading"/>
    <WordGroupHorizontalList
		v-if="!subGroupLoading"
		:items="subGroups"
		:on-sub-group-click="subGroupClick"
		:group-title="activeMainGroup.fa"/>

    <div class="groups">
      <div class="box-title">
        <div class="roll"></div>
        <span>گروه ها</span>
      </div>
		<MainGroupSkeleton v-if="mainGroupLoading"/>

		<ItemGroupVerticalList
			:items="mainGroups"
			:on-group-click="setActiveMainGroup"
			v-if="!mainGroupLoading" />
    </div>
  </div>

  <show-alert-modal
	:item="{
		text:'بعد از نمایش کامل تبلیغ گروه برای شما باز خواهد شد',
		btnTitle:'دیدن تبلیغ'
	}"
    :on-ok-click="goToAdvertisement"/>

	<FilterBanner :show="filterBannerShow" :on-btn-click="changeFilterBanner"/>

</template>

<script>
import {Modal} from "bootstrap";
import WordGroupHorizontalList from "@/components/WordGroupHorizontalList.vue";
import ItemGroupVerticalList from "@/components/ItemGroupVerticalList.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import API_MAIN_GROUPS from "@/api/API_MAIN_GROUPS";
import MainGroupSkeleton from "@/components/skeleton/MainGroupSkeleton.vue";
import API_SUB_GROUPS from "@/api/API_SUB_GROUPS";
import SubGroupSkeleton from "@/components/skeleton/SubGroupSkeleton.vue";
import API_USER from "@/api/API_USER";
import FilterBanner from "@/components/FilterBanner.vue";

export default {
  components: {FilterBanner,SubGroupSkeleton, MainGroupSkeleton, ShowAlertModal, ItemGroupVerticalList, WordGroupHorizontalList},

	mounted() {

    this.showAdvertisementAlertModal = new Modal(document.getElementById('show-alert-modal'));
	this.userInfo=JSON.parse(localStorage.getItem("userData"));
	this.getMainGroups();

	this.getUserInfo();


	//Android Custom Event
	document.addEventListener('openSubGroupMethod', this.openSubGroupMethod);
	document.addEventListener('showFilterBannerMethod', this.showFilterBannerMethod);

	},
	methods: {

		showFilterBannerMethod(){
			this.filterBannerShow=true;
		},

		changeFilterBanner(show){
			this.filterBannerShow=show;
		},

		openSubGroupMethod(){
			let params={
				"subGroupId":this.activeSubGroup.subGroupId
			};
			new API_USER().openSubGroup(params).then(result => {

				if(result.general.status)
				{
					this.$root.pageRoute('Advertisement');
				}
			});
		},

		getUserInfo(){
			new API_USER().getUserInfo().then(result => {

			if(result.general.status)
			{
				this.userInfo=result.data.user
				localStorage.setItem("userData",JSON.stringify(result.data.user))
			}
		});

		},

		getMainGroups() {

			let that = this;
			that.mainGroupLoading = true;

			new API_MAIN_GROUPS().getMainGroups().then(result => {

				that.mainGroupLoading = false;

				if (result.general.status) {
					let tempMainGroups = result.data.items;

					if (tempMainGroups.length) {
						tempMainGroups.forEach((item) => {
							item.active = false;
						});

						this.mainGroups = tempMainGroups;

						let activeMainMenu = JSON.parse(localStorage.getItem('activeMainGroup'));
						if (activeMainMenu !== null) {
							that.setActiveMainGroup(activeMainMenu)
						} else {
							that.setActiveMainGroup(this.mainGroups[0])
						}

					}
				}
			});

		},

		goToAdvertisement() {
			this.showAdvertisementAlertModal.hide();
			//TODO:Call Video advertisement method in Android
			// eslint-disable-next-line no-undef
			androidListener.showVideoAds("OpenSubGroup");
		},

		setActiveMainGroup(activeItem) {

			this.mainGroups.forEach((item) => {

				if (item.id === activeItem.id) {
					item.active = true;
					this.activeMainGroup = item;
					localStorage.setItem("activeMainGroup", JSON.stringify(item));

					this.getSubGroup(this.activeMainGroup.id)
				} else {
					item.active = false;
				}
			});
		},

		getSubGroup(mainGroupId) {
			let that = this;
			let params = {
				"mainGroupId": mainGroupId
			};

			that.subGroupLoading = true;

			new API_SUB_GROUPS().getSubGroups(params).then(result => {
				that.subGroupLoading = false;

				if (result.general.status) {
					that.subGroups = result.data.items;

					that.subGroups.sort((a, b) => b.opened - a.opened);
				}
			});
		},

		subGroupClick(item){

			this.activeSubGroup=item;

			if (!item.opened){
				this.showAdvertisementAlertModal.show();
			}
		},
	},
	data() {
		return {
			filterBannerShow:false,
			mainGroupLoading:true,
			subGroupLoading:true,
			activeMainGroup:null,
			activeSubGroup:null,
			showAdvertisementAlertModal:null,
			mainGroups:[],
			subGroups:[],
			userInfo:'',
		}
	},
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.home-content
{
  display: flex;
  flex-direction: column;

  .groups
  {
    display: flex;
    flex-direction: column;
    margin:15px 0;

    .box-title {
      display: flex;
      margin: 5px 0;
      align-items: center;
      flex-direction: row;
      margin-inline:5%;

      span {
        color: $name-box-color;
        font-weight: bold;
        font-size: $normal-font-size+2;
        white-space: nowrap;
      }

      .roll {
        background-color: $roll-box-color;
        width: 100%;
        height: 1px;
        margin-inline: 10px;
      }
    }


  }


}

</style>