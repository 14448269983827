<template>
  <div class="gem-content">

    <div class="reward wrapper-content">
      <div class="gift">
        <img src="../assets/img/gift.svg" alt="gift">
      </div>
      <div class="text">
        <span class="see">ببین و جایزه بگیر</span>
        <div class="timer">
          <span class="showTimer" v-if="!btnShow && remainingTime!=null">{{remainingTime}}</span>
          <span class="active" v-if="btnShow">با مشاهده تبلیغات میتونی امتیاز خودتو افزایش بدی</span>
        </div>
      </div>
      <div class="button">
        <div class="btn-success"  v-if="btnShow && remainingTime!=null" @click="showAd">
          <span>مشاهده تبلیغات</span>
        </div>
        <div class="btn-no" v-if="!btnShow  && remainingTime!=null" >
          <span>مشاهده تبلیغات</span>
        </div>
      </div>
    </div>
  </div>
	<FilterBanner :show="filterBannerShow" :on-btn-click="changeFilterBanner"/>

</template>

<script>


import API_USER from "@/api/API_USER";
import FilterBanner from "@/components/FilterBanner.vue";

export default {
  components: {FilterBanner},
  mounted() {

	this.getGemTime()

	//Android Custom Event
	document.addEventListener('SetGemScoreMethod', this.SetGemScoreMethod);
	document.addEventListener('showFilterBannerMethod', this.showFilterBannerMethod);
  },
  methods: {

	getGemTime(){
		let that=this
		new API_USER().getGemTime().then(result => {

			if(result.general.status)
			{
				that.duration=result.data.gemTime.value;
				that.remainingTime= result.data.gemTime.value;

				that.countDownTimer();
			}
		});

	},

	showFilterBannerMethod(){
	this.filterBannerShow=true;
	},

	changeFilterBanner(show){
	this.filterBannerShow=show;
	},

	SetGemScoreMethod(){
	this.videoAdvertisementWatched=true;
	new API_USER().setVideoAdvertisementScore().then(result => {

			if(result.general.status)
			{
				this.videoAdvertisementWatched=false;
				this.btnShow = false;
				clearInterval(this.interval);
				localStorage.removeItem('timerStartTime');
				this.$root.pageRoute('Advertisement');
			}
		});
	},

	showAd(){
		//TODO:Call Video advertisement method in Android
		// eslint-disable-next-line no-undef
		androidListener.showVideoAds("SetGemScore");
	},

    getMin() {
      let min = parseInt(this.countDown / 60, 10) % 60
      if (min < 10) {
        return '0' + min;
      } else {
        return min;
      }
    },
    getSec() {
      let sec = this.countDown % 60
      if (sec < 10) {
        return '0' + sec;
      } else {
        return sec;
      }
    },
    countDownTimer() {

		const startTime = localStorage.getItem('timerStartTime');

		if (startTime) {
		const elapsedTime = Math.floor((Date.now() - parseInt(startTime)) / 1000);
		this.remainingTime = this.duration - elapsedTime;

		if (this.remainingTime <= 0) {
			this.btnShow = true
			this.remainingTime = 0;
			return;
		}
		} else {
			localStorage.setItem('timerStartTime', Date.now());
		}

		this.interval = setInterval(() => {
			if (this.remainingTime > 0) {
				this.remainingTime--;
			} else {
				this.btnShow = true

			}
		}, 1000);
    },
  },
  data() {
    return {
		videoAdvertisementWatched:false,
		filterBannerShow:false,
		duration: null,
		remainingTime: null,
		interval: null,
		btnShow: false,
    }
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";
.gem-content
{
  .reward {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    background-color: $reward-color;

    .gift
    {
      width: 100%;
      display: flex;
      justify-content: center;
      img
      {
        width: 20%;
      }
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .timer {
        align-items: center;
        display: flex;
        flex-direction: column;
        color: #FFEE43 !important;
        margin-bottom: 10px;
      }
      .see {
        color: $name-box-color;
        margin: 15px 0;
        font-size: $normal-font-size;
      }

      .active {
        color: $key-color;
        font-size: $small-font-size;
      }


    }

    .button
    {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      .btn-success {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          background-color: $text-color !important;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $background-color;
          font-size: $small-font-size;
          width: 100%;
          font-weight: 700;
          user-select: none;
          cursor: pointer;
          border-radius: 5px;
        }
      }
      .btn-no {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          background-color: $roll-box-color;
          height: 40px;
          display: flex;
          user-select: none;
          justify-content: center;
          align-items: center;
          color: $background-color;
          font-size: $small-font-size;
          width: 100%;
          cursor: pointer;
          font-weight: 700;
          border-radius: 5px;
        }
      }
    }
  }
}

</style>