<template>

  <show-alert-modal
    :item="{
    text:alertText,
    btnTitle:'بله . متوجه شدم'
   }"
    :on-ok-click="()=>{showTitleModalVariable.hide()}"
  />

  <div class="vertical-list">
    <div class="items">
      <div class="item" v-for="(item,index) in items" :key="index">
        <div class="transaction">
          <div class="top">
            <span class="dt">{{item.registerDate}}</span>
            <div class="cost">
              <span :style="{color:item.statusColor}">{{new Intl.NumberFormat('en-US').format(item.price)}} تومان</span>
            </div>

          </div>
          <div class="center">
            <div class="number-card">
              <div class="state" @click="showAlert(item)">
                <span :style="{color:item.statusColor}">{{ item.status.title }}</span>
                <font-awesome-icon :style="{}" class="i-icon" icon="fa-solid fa-magnifying-glass"/>
              </div>
              <span>{{ item.cartNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import ShowAlertModal from "@/components/ShowAlertModal.vue";
import {Modal} from "bootstrap";

export default {
  components: {ShowAlertModal},

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.showTitleModalVariable = new Modal(document.getElementById('show-alert-modal'));
  },
  methods: {

    showAlert(item){
      this.alertText=item.status.title+" **** "+item.description
      this.showTitleModalVariable.show()
    }
  },

  data() {
    return {
      showTitleModalVariable: null,
      alertText:'',
    }
  },

}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.vertical-list {
  display: flex;
  flex-direction: row;

  .items {
    width: 100%;
    align-items: start;
    margin: 0;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row-reverse;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {

      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 5px;
      align-items: center;
      padding: 5px;
      margin-inline: 10px;

      .transaction {
        width: 100%;
        padding: 10px;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: $reward-color;

        .top {
          display: flex;
          width: 100%;
          justify-content: space-between;
          font-size: $small-font-size;

          .cost {
            color: $text-color;
            direction: rtl;

          }

          .dt {
            color: $key-color;
          }
        }

        .center {
          width: 100%;
          display: flex;
          margin: 10px 0;
          justify-content: flex-end;
          flex-direction: row;

          .number-card {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            color: $key-color;
            font-size: $small-font-size;

            .state {
              display: flex;
              flex-direction: row;
              align-items: center;

              span {
                color: $yel-color;
                font-size: $very-small-font-size;
              }

              .i-icon {
                margin-inline-start: 10px;
              }
            }
          }
        }

        .bottom {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .button {
            display: flex;
            align-items: center;
            padding: 10px;
            color: $no-color;
            user-select: none;
            cursor: pointer;
            width: 30%;
            justify-content: center;
            height: 30px;
            font-size: $very-small-font-size;
            border-radius: 5px;
            border: 1px solid $no-color;

            span {
              margin-inline: 5px;
            }
          }
        }
      }
    }
  }
}

</style>