import API from "@/api/API";

export default class API_WORD extends API {
    getWords=(params) => {
        return this.post("Word/GetWords",params);
    }

    getWordsWithEnQuiz=(params) => {
        return this.post("Word/GetWordsWithENQuiz",params);
    }

    getWordsWithFaQuiz=(params) => {
        return this.post("Word/GetWordsWithFAQuiz",params);
    }

    getWordsWithSpellQuiz=(params) => {
        return this.post("Word/GetWordsWithSpellQuiz",params);
    }
}