<template>
  <div class="login-header-content wrapper-content">
	  <div class="header-menus">
		  <div class="sl-item">
			  <div class="i-content wrapper-content">
				  <div class="left "></div>
				  <div class="right">
					  <div class="icon">
						  <font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>

	  <div class="text-box" v-if="userInfo!=null && !userInfo.isRegisterReferral">
		  <span class="text">کد معرفی دوست خود را وارد نمایید</span>
	  </div>
	  <div class="buttons" v-if="userInfo!=null && !userInfo.isRegisterReferral">
		  <div class="btn-input">
			  <input type="text" v-model="referral">
		  </div>
		  <div class="btn-on" @click="registerReferral">
			  <span>ثبت کد معرفی</span>
		  </div>
	  </div>

	  <div class="text-box"  v-if="userInfo!=null && userInfo.isRegisterReferral">
		  <span class="text">شما قبلا کد معرفی دوست خود را ثبت کرده اید</span>
	  </div>
	  <div class="buttons"  v-if="userInfo!=null && userInfo.isRegisterReferral">
		  <div class="btn-input">
			  <input type="text" readonly :value="userInfo.destinationReferral">
		  </div>

	  </div>

  </div>
	<LoadingFullScreen :show="showLoading"/>

	<show-alert-modal
		:item="{
			text:this.verifyAlertText,
			btnTitle:'متوجه شدم'
         }"
		:on-ok-click="()=>{this.showAlertModal.hide()}"
	/>
</template>

<script>

import API_USER from "@/api/API_USER";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import {Modal} from "bootstrap";
import {register} from "register-service-worker";

export default {
	components: {ShowAlertModal, LoadingFullScreen},
	beforeMount() {
		this.userInfo=JSON.parse(localStorage.getItem("userData"))

	},
	mounted() {
		this.showAlertModal = new Modal(document.getElementById('show-alert-modal'));
	},
	methods:{

		registerReferral(){

			let params={
				"referralCode":this.referral
			};

			this.showLoading=true;

			new API_USER().registerReferralCode(params).then(result => {

				this.showLoading=false;

				if(result.general.status)
				{
					this.verifyAlertText=result.general.text
					this.showAlertModal.show();
				}
				else
				{
					this.verifyAlertText=result.general.text
					this.showAlertModal.show();
				}
			});
		}
	},
	data(){
		return {
			referral:'',
			userInfo:null,
			showLoading:false,
			verifyAlertText:'',
			showAlertModal:null
		}
	},
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.login-header-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .text-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 10px 0;

    .text {
      color: #DFDAFD;
    }
  }
}

</style>