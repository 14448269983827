<template>
  <div v-if="show" class="banner-box">
    <div class="title">توجه کنید</div>
    <div class="text">برای کسب درآمد و استفاده از همه امکانات اپلیکیشن لطفا با فیلترشکن از اپلیکیشن استفاده نمایید</div>

	<div class="button">
		<div class="btn-success" @click="onBtnClick(!show)">
			<span>فهمیدم</span>
		</div>
	</div>
  </div>

</template>

<script>
export default
{
  props:{
    show:{
      type:Boolean,
      required:true,
    },
	onBtnClick: {
		type: Function,
		required:true
	},
  },
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.banner-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;

	.button
	{
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 20px 0;
		.btn-success {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				background-color: $text-color !important;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $background-color;
				font-size: $small-font-size;
				width: 100%;
				font-weight: 700;
				user-select: none;
				cursor: pointer;
				border-radius: 5px;
			}
		}
		.btn-no {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				background-color: $roll-box-color;
				height: 40px;
				display: flex;
				user-select: none;
				justify-content: center;
				align-items: center;
				color: $background-color;
				font-size: $small-font-size;
				width: 100%;
				cursor: pointer;
				font-weight: 700;
				border-radius: 5px;
			}
		}
	}

  .title {
	color: $name-box-color;
	margin: 15px;
	font-weight: bold;
	font-size: $super-extra-font-size;
  }

	.text {
		color: $yel-color;
		margin: 15px;
		line-height: 30px;
		text-align: center;
		font-size: $large-font-size;
	}
}
</style>