<template>
  <div class="gem-content" v-for="(item,index) in items" :key="index">
    <div class="box-title">
      <div class="roll"></div>
      <span>{{ item.mainGroup.fa }}</span>
    </div>
    <SaveWordHorizontalList  :group-items="item.subGroups"/>
  </div>
</template>

<script>

import SaveWordHorizontalList from "@/components/SaveWordHorizontalList.vue";

export default {
  components: {SaveWordHorizontalList},
  props: {
    items: {
      type: Array,
      required: true,
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.box-title {
  display: flex;
  margin: 5px 0;
  align-items: center;
  margin-inline: 5%;


  span {
    color: $name-box-color;
    font-weight: bold;
    font-size: $normal-font-size+2;
    min-width: fit-content;
  }

  .roll {
    background-color: $roll-box-color;
    display: flex;
    height: 1px;
    flex: 1;
    margin-inline: 10px;
  }
}
</style>