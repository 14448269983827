import API from "@/api/API";

export default class API_SAVE extends API {
    changeStatus=(params) => {
        return this.post("Save/ChangeStatus",params);
    }

    getSavedWords=(params) => {
        return this.post("Save/GetSavedWords",params);
    }

}