<template>

  <div class="saved-skeleton wrapper-content">

    <div class="item" v-for="(item) in 5 " :key="item">
      <div class="title skeleton"></div>
      <div class="s-items">
        <div class="v-item skeleton"></div>
        <div class="v-item skeleton"></div>
        <div class="v-item skeleton"></div>
        <div class="v-item skeleton"></div>
        <div class="v-item skeleton"></div>
        <div class="v-item skeleton"></div>
      </div>
    </div>
  </div>

</template>

<script>

export default
{

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/style";

.saved-skeleton
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .item
  {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    direction: rtl;

    .title
    {
      height: 10px;
      border-radius: 5px;
      width: 30%;
      margin-bottom: 10px;
    }

    .s-items
    {
      display: flex;
      margin-bottom: 10px;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar
      {
        display: none;
      }

      .v-item
      {
        height: 100px;
        min-width: 150px;
        border-radius: 5px;
        margin: 2px;
      }
    }
  }
}


</style>