import axios from "axios";
import {DEFINES} from "@/incloude/defines";
import {GLOBAL} from "@/incloude/global";


class Axios {
    constructor()
    {
        return axios.create()
    }
}

export default class API extends Axios {
    constructor()
    {
        super();

        this.defaults.baseURL=DEFINES.API_URL;

        if (localStorage.getItem("token"))
        {
            this.defaults.headers.get.token=localStorage.getItem("token");
            this.defaults.headers.post.token=localStorage.getItem("token");
        }

        //let that=this;

        this.interceptors.response.use(
            (response) => {
                let data=response.data;
                
                return data;
            },
            (error) => {
                if(error.response.status===401)
                {
                    //localStorage.clear();
                    GLOBAL.router.push("/Splash")
                }
                
                return Promise.resolve(error.response.data);
            }
        )
    }
}