<template>
  <div class="login-header-content wrapper-content">
    <div class="pic">
      <img src="../assets/img/logo.png" alt="logo">
    </div>
    <div class="text-box">
      <span class="text">ایمیل خود را وارد نمایید </span>
    </div>
    <div class="buttons">
      <div class="btn-input">
        <input type="text" id="email" v-model="email">
      </div>
      <div class="btn-on" @click="userLogin">
        <span>دریافت کد ورود</span>
      </div>
    </div>
  </div>
	<LoadingFullScreen :show="showLoading"/>

	<show-alert-modal
		:item="{
			text:this.verifyAlertText,
			btnTitle:'متوجه شدم'
         }"
		:on-ok-click="()=>{this.showAlertModal.hide()}"
	/>
</template>

<script>

import API_USER from "@/api/API_USER";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import {Modal} from "bootstrap";

export default {
	components: {ShowAlertModal, LoadingFullScreen},
	mounted() {
		this.showAlertModal = new Modal(document.getElementById('show-alert-modal'));
	},
	methods:{
		userLogin(){

			let params={
				"email":this.email
			};

			this.showLoading=true;

			new API_USER().login(params).then(result => {

				this.showLoading=false;

				if(result.general.status)
				{
					localStorage.setItem("email",this.email)
					localStorage.setItem("token",result.data.token)
					localStorage.setItem("verifyCode",result.data.verifyCode)
					this.$root.pageRoute('LoginValidation');
				}
				else
				{
					this.verifyAlertText=result.general.text
					this.showAlertModal.show();
				}
			});
		}
	},
	data(){
		return {
			email:'',
			showLoading:false,
			verifyAlertText:'',
			showAlertModal:null
		}
	},
}
</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.login-header-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .text-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 10px 0;

    .text {
      color: #DFDAFD;
    }
  }
}

</style>