<template>

	<div class="word-review-content ">

		<div class="header-menus">
			<div class="sl-item">
				<div class="i-content wrapper-content">
					<div class="left ">

					</div>
					<div class="right">
						<div class="icon">
							<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right" @click="$root.pageRouteBack()"/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="items wrapper-content ">
			<div class="up">
				<div class="box-title">
					<div class="roll"></div>
					<span>{{subGroup.fa}}</span>
				</div>
				<div class="show-word">
					<div class="img" :style="{ backgroundColor: getRandomColor() }">
						<img :src="currentWord.imageUrl" :alt="currentWord.en">
					</div>
					<div class="title">
						<span class="name">{{currentWord.en}}</span>
						<div class="roll"></div>
						<div class="mean" :class="{'d-none': !showTranslate}"><span>{{currentWord.fa}}</span></div>
					</div>
					<div class="control-btn">
						<font-awesome-icon class="b-icon" :class="{active: activeSpeech}" icon="fa-solid fa-play" @click="playSpeech(currentWord.en)"/>
						<font-awesome-icon class="b-icon" :class="{active: !showTranslate}" icon="fa-solid fa-eye-slash" @click="hideWord"/>
						<font-awesome-icon class="b-icon" :class="{active: currentWord.isSave}" icon="fa-solid fa-bookmark" @click="saveWord(currentWord.id)"/>
					</div>
				</div>
			</div>
			<div class="down">
				<div class="button">
					<div class="end-items">
						<div class="next-bar">
							<div class="left" @click="goBackWord">
								<div class="icon">
									<font-awesome-icon class="b-icon" icon="fa-solid fa-angle-left"/>
								</div>
							</div>
							<div class="center">
								<div class="left">
									<p>{{words.length}}</p>
									<p class="count"> / {{getCurrentWordIndex(currentWord)+1}}</p>
								</div>
							</div>
							<div class="right" @click="goNextWord">
								<div class="icon">
									<font-awesome-icon class="b-icon" icon="fa-solid  fa-angle-right"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>


import backdrop from "bootstrap/js/src/util/backdrop";
import API_SAVE from "@/api/API_SAVE";
import {toRaw} from "vue";

export default {
	beforeMount() {
		this.words=JSON.parse(localStorage.getItem('savedWords'))
	},
	computed: {
		backdrop() {
			return backdrop
		}
	},
	components: { },
	mounted() {
		this.showTranslate = JSON.parse(localStorage.getItem("showTranslate"));
		this.setCurrentWord(0);
	},
	methods: {
		toRaw,

		setCurrentWord(arrayIndex) {
			this.currentWord = this.words[arrayIndex];
			this.subGroup = toRaw(this.currentWord.subGroup);
		},

		getCurrentWordIndex(current) {

			let placeNumber = 0
			this.words.forEach((item, index) => {
				if (current.id === item.id) {
					placeNumber = index;
				}
			});

			return placeNumber;

		},

		goNextWord() {
			let currentIndex = this.getCurrentWordIndex(this.currentWord);
			if (currentIndex + 1 < this.words.length) {
				this.setCurrentWord(currentIndex + 1)
			}
		},

		goBackWord() {
			let currentIndex = this.getCurrentWordIndex(this.currentWord);
			if (currentIndex - 1 >= 0) {
				this.setCurrentWord(currentIndex - 1)
			}
		},

		playSpeech(word) {
			//TODO:Call Speech
			// eslint-disable-next-line no-undef
			androidListener.speechWord(word);
			/*let that = this;
			that.activeSpeech = true;

			const msg = new SpeechSynthesisUtterance();
			msg.text = word;
			msg.volume = 1.0; // speech volume (default: 1.0)
			msg.pitch = 1.0; // speech pitch (default: 1.0)
			msg.rate = 1.0; // speech rate (default: 1.0)
			msg.lang = 'en-US'; // speech language (default: 'en-US')
			msg.voiceURI = 'Google UK English Female'; // voice URI (default: platform-dependent)
			msg.onboundary = function (e) {
				e.preventDefault()
			};
			msg.onpause = function (e) {
				e.preventDefault()
			};
			msg.onend = function (e) {
				e.preventDefault();
				that.activeSpeech = false;
			};

			window.speechSynthesis.speak(msg);*/

		},

		hideWord() {
			localStorage.setItem("showTranslate", JSON.stringify(!this.showTranslate));
			this.showTranslate = !this.showTranslate;
		},

		saveWord(wordId) {
			this.words.forEach((item) => {
				if (wordId === item.id) {
					item.isSave = !item.isSave;

					let params = {
						"wordId": wordId
					};

					new API_SAVE().changeStatus(params).then(result => {
						console.log(result)
					});

				}
			});

		},

		getRandomColor() {
			let letters = '0123456789ABCDEF';
			let color = '#';
			for (let i = 0; i < 6; i++) {
				color += letters[Math.floor(Math.random() * 16)];
			}
			return color;
		}

	},
	data() {
		return {
			currentWord: '',
			subGroup: '',
			activeSpeech: false,
			showTranslate: false,
			words: [],
		}
	}
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";

.word-review-content {

	.items {

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.up {

			.box-title {
				display: flex;
				margin: 12px 0;
				align-items: center;
				flex-direction: row;


				span {
					color: $name-box-color;
					font-weight: bold;
					font-size: $normal-font-size+2;
					min-width: fit-content;
				}

				.roll {
					background-color: $roll-box-color;
					width: 100%;
					height: 1px;
					margin-inline: 10px;
				}
			}

			.show-word {
				display: flex;
				width: 100%;
				flex-direction: column;
				background-color: $background-color-2;
				border-radius: 5px;
				border: 1px solid $color-number;

				.img {
					width: 100%;
					height: 160px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px 5px 0 0;

					img {
						max-width: 90%;
						max-height: 90%;
						vertical-align: middle;
						object-fit: cover;
					}
				}

				.title {
					margin-top: 20px;
					width: 100%;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					.name {
						color: $text-color;
						font-weight: bold;
						font-size: $large-font-size;
					}

					.roll {
						width: 90%;
						margin-top: 15px;
						height: 1px;
						background-color: $roll-box-color;
					}

					.mean {
						color: $name-box-color;
						font-size: $small-font-size;
						margin-top: 15px;
					}

				}

				.control-btn {
					margin: 20px 0;
					flex-direction: row;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;

					.b-icon {
						width: 40px;
						display: flex;
						cursor: pointer;
						height: 40px;
						align-items: center;
						justify-content: center;
						border-radius: 70px;
						font-size: $small-font-size;
						padding: 10px;
						background-color: $background-color;
						margin: 10px;
						color: $key-color;

						&.active {
							color: $text-color;
						}
					}
				}
			}
		}

		.down {
			.button {
				justify-content: center;
				display: flex;
				width: 100%;

				.end-items {
					width: 100%;


					.next-bar {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;

						.b-icon {
							width: 50px;
							height: 50px;
							display: flex;
							color: $text-color;
							align-items: center;
							justify-content: center;
							border-radius: 100px;
							padding: 15px;
							font-size: $super-extra-font-size;
							background-color: $text-color-2;
						}

						.left {
							display: flex;
							flex-direction: row;
							align-items: center;
							cursor: pointer;


							span {
								font-size: $small-font-size;
								margin-block-start: 5px;
								color: $text-color;
								margin-block: 10px;
								margin-inline: 10px;

							}
						}

						.center {
							background-color: $reward-color;
							display: flex;
							height: 40px;
							padding: 5px;
							border-radius: 30px;
							flex: 1;
							align-items: center;
							font-size: $small-font-size;
							justify-content: center;
							flex-direction: row;
							margin-inline: 10px;

							.left {
								margin-inline: 10px;
								color: $name-box-color;

								.count {
									color: $text-color;
								}
							}

							.right {
								margin-inline: 10px;
								color: $text-color;

								.i-icon {
									margin-inline: 5px;
									font-size: $small-icon-size;
								}
							}
						}

						.right {
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
						}
					}
				}
			}
		}

	}
}
</style>