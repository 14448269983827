<template>
  <div class="login-header-content wrapper-content">
    <div class="pic">
      <img src="../assets/img/logo.png" alt="logo">
    </div>
    <div class="text-box">
      <span class="text">کد دریافتی را وارد نمایید</span>
      <span class="number">{{userMobile}}</span>
      <div class="timer">
        <span class="showTimer" v-if="!showReSendCode">{{ getMin() }}:{{ getSec() }}</span>
        <span class="active" v-if="showReSendCode">زمان شما به اتمام رسیده است</span>
      </div>
    </div>
    <div class="buttons">
      <div class="btn-input">
        <input type="number" id="number" v-model="code" @input="numberCountCheck">
      </div>
      <div class="btn-on" v-if="activeBtn && !showReSendCode" @click="checkVerifyCode">
        <span>ورود</span>
      </div>
      <div class="btn-sent" v-if="showReSendCode" @click="resetForm">
        <span>ارسال مجدد کد فعالسازی</span>
      </div>
      <div class="btn-submit" v-if="!activeBtn && !showReSendCode">
        <span>ورود</span>
      </div>
    </div>
  </div>

	<LoadingFullScreen :show="showLoading"/>

	<show-alert-modal
    :item="{
      text:this.verifyAlertText,
      btnTitle:'متوجه شدم'
    }"
    :on-ok-click="()=>{this.showAlertModal.hide()}"
  />
</template>

<script>


import {Modal} from "bootstrap";
import ShowAlertModal from "@/components/ShowAlertModal.vue";
import API_USER from "@/api/API_USER";
import LoadingFullScreen from "@/components/LoadingFullScreen.vue";

export default {
  components: {LoadingFullScreen, ShowAlertModal},
  mounted() {
    this.showAlertModal = new Modal(document.getElementById('show-alert-modal'));
  },
  created() {
    this.countDownTimer();
	this.email=localStorage.getItem("email");
	this.verifyCode=localStorage.getItem("verifyCode");
  },
  methods: {

    checkVerifyCode() {

	console.log(this.verifyCode);

	let params={
		"verifyCode":this.code,
	};
	new API_USER().loginVerify(params).then(result => {

		this.showLoading=false;

		if(result.general.status)
		{
		localStorage.setItem("userData",JSON.stringify(result.data.user))
		localStorage.setItem("token",result.data.user.userToken)
		localStorage.setItem("verifyCode",'')
		this.$root.pageRoute('Home');
		}
		else
		{
		this.verifyAlertText=result.general.text
		this.showAlertModal.show();
		}
		});
	},

    // ریست کردن مقادیر صفحه برای دریافت مجدد کد تایید
    resetForm() {
		this.countDown = 300;
		this.countDownTimer();
		this.showReSendCode = false;
		this.activeBtn = false;
		this.code = '';
		this.$root.pageRoute('Login');
    },

    numberCountCheck(e) {
      const value = e.target.value
      if (value.length === 6) {
        this.activeBtn = true;
      } else {
        this.activeBtn = false;
      }
    },

    getMin() {
      let min = parseInt(this.countDown / 60, 10) % 60
      if (min < 10) {
        return '0' + min;
      } else {
        return min;
      }
    },

    getSec() {
      let sec = this.countDown % 60
      if (sec < 10) {
        return '0' + sec;
      } else {
        return sec;
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
		this.showReSendCode = true
      }
    },
  },

  data() {
    return {
		code: '',
		email:'',
		verifyCode:'',
		countDown: 300,
		showReSendCode: false,
		activeBtn: false,
		showAlertModal: null,
		verifyAlertText:'',
		showLoading:false
    }
  },
}

</script>

<style lang="scss" scoped>

@import "../assets/css/scss/style";


.login-header-content {
  display: flex;
  min-height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .text-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;

    .text {
      color: #DFDAFD;

    }

    .number {
      color: #59566E;
      margin: 5px 0;
      font-size: 12px;
    }

    .timer {
      align-items: center;
      display: flex;
      flex-direction: column;
      color: #FFEE43;
      margin-bottom: 10px;
    }
  }

}
</style>