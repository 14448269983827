<template>

  <div class="wallet-skeleton">

    <div class="item skeleton"></div>
  </div>

</template>

<script>

export default
{

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/style";

.wallet-skeleton
{
  display: flex;

  .item
  {
    display: flex;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    align-self: center;
  }
}


</style>